import React from "react";
import { Box, Stack, Container, Grid, Link, Typography, Divider, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "../../../assets/images/ForsteLogo.svg";
import Wave from "../../wave";
import theme from "../../../styles/theme";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <Wave
        amplitude={30}
        duration={10}
        fillStyle={theme.palette.background.dark}
        frequency={3}
        waveHeightRatio={0.3}
  
      />
      <Box
        sx={{
          color: "white",
          bgcolor: "background.dark",
          pb: 6,
          // mt: 5,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="subtitle1"
                color="inherit"
                sx={{ fontWeight: "600" }}
                gutterBottom
              >
                {t("Footer.Legal.Label")}
              </Typography>
              <Link
                variant="subtitle2"
                href="#"
                color="#D1D1D1"
                underline="hover"
                sx={{ fontWeight: "400" }}
              >
                {t("Footer.Legal.Terms")}
              </Link>
              <br />
              <Link
                variant="subtitle2"
                href="#"
                color="#D1D1D1"
                underline="hover"
                sx={{ fontWeight: "400" }}
              >
                {t("Footer.Legal.Privacy")}
              </Link>
              <br />
              <Link
                variant="subtitle2"
                href="#"
                color="#D1D1D1"
                underline="hover"
                sx={{ fontWeight: "400" }}
              >
                {t("Footer.Legal.Cookies")}
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="subtitle1"
                color="inherit"
                sx={{ fontWeight: "600" }}
                gutterBottom
              >
                {t("Footer.Support.Label")}
              </Typography>
              <Link
                variant="subtitle2"
                href="#"
                color="#D1D1D1"
                underline="hover"
                sx={{ fontWeight: "400" }}
              >
                {t("Footer.Support.Contact")}
              </Link>
            </Grid>
          </Grid>

          <Divider sx={{ bgcolor: "grey.700", my: 2, mt: 10 }} />

          <Grid container spacing={5} justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
              {t("Footer.Contact")}
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "300" }}>
              {t("Email")}
              </Typography>
            </Grid>

            <Grid item>
              <Stack direction="row" spacing={0}>
                <IconButton color="inherit">
                  <FacebookIcon />
                </IconButton>
                <IconButton color="inherit">
                  <InstagramIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ bgcolor: "grey.700", my: 2 }} />

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid display="flex" alignItems="center">
              <img src={logo} alt="Logo" height={35} />
              <Typography
                variant="h5"
                fontFamily="Century Gothic"
                sx={{ mx: 1, fontWeight: "800" }}
              >
                FØRSTEINNTRYKK
              </Typography>
            </Grid>

            <Typography variant="body2" color="inherit">
              © 2024, Førsteinntrykk AS.
            </Typography>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
