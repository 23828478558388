// import React, { useState } from "react";
import { Container, Paper, Typography, } from "@mui/material";
import { useTranslation } from "react-i18next";

const SignupPage = ({ onSignup }) => {
  const {t} = useTranslation();
  // const [username, setUsername] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [error, setError] = useState("");

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   if (password !== confirmPassword) {
  //     setError("Passwords do not match.");
  //     return;
  //   }
  //   try {
  //     await onSignup(username, email, password);
  //   } catch (error) {
  //     setError("Signup failed. Please try again.");
  //   }
  // };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 6, display: "flex", flexDirection: "column", alignItems: "center", mx: 2 }}>
        <Typography component="h1" variant="h3" fontWeight={"600"}>
          {t("SignUpPage.TempHi")}
        </Typography>
        <Typography component="p" color={"text.secondary"} fontWeight={"500"}>
        {t("SignUpPage.TempRegi")}
        </Typography>
        <Typography component="h5" variant="h5" color={"primary"} fontWeight={"600"}>
        {t("Email")}
        </Typography>
        <Typography component="p" color={"text.secondary"} fontWeight={"500"} my={"20%"}>
        {t("SignUpPage.TempThx")}
        </Typography>
        
        {/* Til senere */}
        {/* <Typography component="h1" variant="h4" fontWeight={"600"}>
          Opprett Konto
        </Typography>
        <Typography component="p" color={"text.secondary"} fontWeight={"500"} marginBottom={2}>
          Fyll ut feltene for å lage en ny bruker!
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Brukernavn"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            id="email"
            label="E-post"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="password"
            label="Passord"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="confirmPassword"
            label="Bekreft Passord"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockPersonIcon />
                </InputAdornment>
              ),
            }}
          />

          {error && (
            <Typography color="error" textAlign="center" marginTop={2}>
              {error}
            </Typography>
          )}

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Registrer
          </Button>

          <Box textAlign="center">
            <Typography>
              Allerede en bruker?{" "}
              <Link component={RouterLink} to="/login" variant="body2">
                Logg inn
              </Link>
            </Typography>
          </Box> 
        </Box>*/}
      </Paper>
    </Container>
  );
};

export default SignupPage;
