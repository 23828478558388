import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Badge,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";
import { useOrder } from "../../../hooks/useOrder";
import { useNavigate } from "react-router-dom";

function OrderHistory() {
  const { t } = useTranslation();
  const { userDetails } = useAuth();
  const { getUserOrders } = useOrder();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // Filtered orders based on search/filter
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering orders
  const [anchorEl, setAnchorEl] = useState(null); // Anchor for filter menu
  const navigate = useNavigate();

  const handleRowClick = (orderId) => {
    navigate(`/dashboard/order/${orderId}`); // Navigate to order page
  };

  useEffect(() => {
    if (userDetails?.user_id) {
      const fetchOrders = async () => {
        try {
          const userOrders = await getUserOrders(userDetails.user_id);
          const sortedOrders = userOrders.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setOrders(sortedOrders);
          setFilteredOrders(sortedOrders); // Initialize filtered orders
        } catch (err) {
          console.error(err);
          setError("Failed to fetch orders."); // Handle error
        }
      };
      fetchOrders();
    }
  }, [userDetails, getUserOrders]);

  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="error"
            badgeContent={t("Dashboard.OrderHistory.Status.Pending")}
          />
        );
      case "in-progress":
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="info"
            badgeContent={t("Dashboard.OrderHistory.Status.InProgress")}
          />
        );
      case "completed":
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="success"
            badgeContent={t("Dashboard.OrderHistory.Status.Completed")}
          />
        );
      case "shipped":
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="secondary"
            badgeContent={t("Dashboard.OrderHistory.Status.Shipped")}
          />
        );
      default:
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="error"
            badgeContent={t("Dashboard.OrderHistory.Status.Unknown")}
          />
        );
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  // Search/filter orders
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const searchValue = event.target.value.toLowerCase();

    const filtered = orders.filter(
      (order) =>
        order.id.toString().includes(searchValue) ||
        formatDate(order.created_at).toLowerCase().includes(searchValue) ||
        order.status.toLowerCase().includes(searchValue)
    );

    setFilteredOrders(filtered);
  };

  // Filter menu actions
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSort = (type) => {
    let sorted;
    switch (type) {
      case "mostRecent":
        sorted = [...filteredOrders].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        break;
      case "oldest":
        sorted = [...filteredOrders].sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        break;
      case "highestAmount":
        sorted = [...filteredOrders].sort((a, b) => b.total_amount - a.total_amount);
        break;
      case "lowestAmount":
        sorted = [...filteredOrders].sort((a, b) => a.total_amount - b.total_amount);
        break;
      default:
        sorted = [...filteredOrders];
        break;
    }
    setFilteredOrders(sorted);
    handleCloseMenu();
  };
  
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        margin: "auto",
        borderRadius: 2,
      }}
    >
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        {/* Label */}
        <Grid item xs={12} md={5}>
          <Typography variant="h4" gutterBottom>
            {t("Dashboard.OrderHistory.Label")}
          </Typography>
        </Grid>

        {/* Search and Filter */}
        <Grid item xs={12} md={7}>
          <Box display="flex" alignItems="center" width="100%">
            <TextField
              fullWidth
              placeholder={t("Dashboard.OrderHistory.Search")}
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ mr: 1 }}
            />
            <IconButton onClick={handleOpenMenu}>
              <FilterList />
            </IconButton>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
              <MenuItem onClick={() => handleSort("mostRecent")}>
                {t("Dashboard.OrderHistory.Sort.MostRecent")}
              </MenuItem>
              <MenuItem onClick={() => handleSort("oldest")}>
                {t("Dashboard.OrderHistory.Sort.Oldest")}
              </MenuItem>
              <MenuItem onClick={() => handleSort("highestAmount")}>
                {t("Dashboard.OrderHistory.Sort.HighestAmount")}
              </MenuItem>
              <MenuItem onClick={() => handleSort("lowestAmount")}>
                {t("Dashboard.OrderHistory.Sort.LowestAmount")}
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>

      {error && <Typography color="error">{error}</Typography>}
      {filteredOrders.length === 0 ? (
        <Typography>{t("Dashboard.OrderHistory.NoOrders")}</Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Dashboard.OrderHistory.Order")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Date")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Total")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Items")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Status.Label")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order, index) => (
                <TableRow
                  key={order.id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "background.light" : "#E0E7F0",
                    "&:hover": {
                      backgroundColor:
                        index % 2 === 0 ? "rgba(181,195,248, 0.35)" : "rgba(180,196,218, 0.45)",
                      transition: "all 0.1s ease-in-out",
                    },
                  }}
                  onClick={() => handleRowClick(order.id)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>
                    <Typography variant="body2" sx={{ fontWeight: "500", color: "primary.main" }}>
                      #{order.id}
                    </Typography>
                  </TableCell>
                  <TableCell>{formatDate(order.created_at)}</TableCell>
                  <TableCell>{`${order.total_amount} kr`}</TableCell>
                  <TableCell>{`${order.OrderItems.length} ${t(
                    "Dashboard.OrderHistory.Items"
                  )}`}</TableCell>
                  <TableCell>{getStatusBadge(order.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

export default OrderHistory;
