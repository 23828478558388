import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useUser } from "../../hooks/useUser";
import { useAuth } from "../../hooks/useAuth";

const OrderFormDialog = ({ open, onClose, onSubmit }) => {
  const { userDetails } = useAuth();
  const { getUserByID, updateUserProfile, user, loading, error } = useUser();
  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    company_name: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
    phone_number: "",
    email: "",
  });

  useEffect(() => {
    if (userDetails && userDetails.user_id) {
      getUserByID(userDetails.user_id);
    }
  }, [userDetails, getUserByID]);

  useEffect(() => {
    if (user) {
      setProfile({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        company_name: user.company_name || "",
        address: user.address || "",
        city: user.city || "",
        country: user.country || "",
        postal_code: user.postal_code || "",
        phone_number: user.phone_number || "",
        email: user.email || "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserProfile(userDetails.user_id, profile);
      onSubmit();
    } catch (error) {
      console.error("Failed to update profile", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Complete Your Order Information</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleFormSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <TextField
                  label="First Name"
                  name="firstname"
                  value={profile.firstname}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField
                  label="Last Name"
                  name="lastname"
                  value={profile.lastname}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Company Name"
                  name="company_name"
                  value={profile.company_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  name="address"
                  value={profile.address}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  name="city"
                  value={profile.city}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country"
                  name="country"
                  value={profile.country}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Postal Code"
                  name="postal_code"
                  value={profile.postal_code}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  name="phone_number"
                  value={profile.phone_number}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  value={profile.email}
                  onChange={handleChange}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleFormSubmit} variant="contained" color="primary">
          Place Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderFormDialog;
