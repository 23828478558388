import React, { useState, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import {
  Container,
  Snackbar,
  Alert,
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Link,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const { loginUser, isError, errorMessage } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
      setTimeout(() => navigate("/dashboard/motif"), 5000); // Redirect after 2 seconds
    }
  }, [navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!emailTouched) setEmailTouched(true);
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    setEmailError(!emailRegex.test(e.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (emailError) {
      return;
    }
    const isSuccess = await loginUser({ email, password });
    if (isSuccess) {
      setOpenSuccessMessage(true);
      setTimeout(() => {
        navigate("/dashboard/motif");
      }, 2000);
    } else {
      console.log(errorMessage);
    }
  };

  if (loggedIn) {
    return (
      <Container component="main" maxWidth="sm">
        <Paper elevation={3} sx={{ padding: 6, display: "flex", flexDirection: "column", alignItems: "center", mx: 2 }}>
          <Typography variant="h5">{t("LogInPage.AlreadyLogged")}  <br/>{t("LogInPage.Redirecting")}</Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 6, display: "flex", flexDirection: "column", alignItems: "center", mx: 2 }}>
        <Typography component="h1" variant="h3" fontWeight={"600"}>
          {t("LogInPage.Welcome")}
        </Typography>
        <Typography component="p" color={"text.secondary"} fontWeight={"500"}>
        {t("LogInPage.Subtitle")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("LogInPage.Email")}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            error={emailTouched && emailError}
            helperText={emailTouched && emailError && t("LogInPage.ValidEmail")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("LogInPage.Password")}
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          {isError && <Alert severity="error">{errorMessage || t("LogInPage.ValidPassword")}.</Alert>}

          <Link href="#" variant="body2" textAlign={"right"} display="block" sx={{ mb: 2 }}>
          {t("LogInPage.ForgottenPass")}
          </Link>

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          {t("LogIn")}
          </Button>

          <Box textAlign="center">
            <Typography>
            {t("LogInPage.NewUser")}{" "}
              <Link component={RouterLink} to="/signup">
              {t("LogInPage.CreateUser")}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Snackbar
        open={openSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setOpenSuccessMessage(false)}
        message={t("LogInPage.LoginSuccess")} 
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setOpenSuccessMessage(false)} severity="success" variant="filled" sx={{ width: "100%" }}>
          {t("LogInPage.LoginSuccess")}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginPage;
