import { createTheme } from "@mui/material/styles";
import ClashGroteskRegular from "../assets/fonts/ClashGrotesk-Regular.woff2";
import ClashGroteskMedium from "../assets/fonts/ClashGrotesk-Medium.woff2";
import ClashGroteskSemiBold from "../assets/fonts/ClashGrotesk-Semibold.woff2";
import ClashGroteskBold from "../assets/fonts/ClashGrotesk-Bold.woff2";
import ClashGroteskLight from "../assets/fonts/ClashGrotesk-Light.woff2";
import ClashGroteskExtralight from "../assets/fonts/ClashGrotesk-Extralight.woff2";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#3158eb",
      dark: "#1b3bb3",
    },
    secondary: {
      main: "#EED23C",
    },
    background: {
      default: "#dfe2e6",
      paper: "#f2f4f6",
      dark: "#212121",
      half: "#808080",
    },
    warning: {
      main: "#F57C00",
    },
    info: {
      main: "#325cff",
    },
    appBar: {
      main: "#dfe2e6",
      contrastText: "#000000",
    },
    text: {
      main: "#1C1D22",
      secondary: "#555",
      light: "#95A0A7",
    },
  },
  typography: {
    fontFamily: "ClashGrotesk, Arial",
  },
  shadows: {
    ...Array(25).fill("none"),
    customShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'ClashGrotesk';
          src: url(${ClashGroteskRegular}) format('woff2');
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: 'ClashGrotesk';
          src: url(${ClashGroteskMedium}) format('woff2');
          font-weight: 500;
          font-style: normal;
        }
        @font-face {
          font-family: 'ClashGrotesk';
          src: url(${ClashGroteskSemiBold}) format('woff2');
          font-weight: 600;
          font-style: normal;
        }
        @font-face {
          font-family: 'ClashGrotesk';
          src: url(${ClashGroteskBold}) format('woff2');
          font-weight: 700;
          font-style: normal;
        }
        @font-face {
          font-family: 'ClashGrotesk';
          src: url(${ClashGroteskLight}) format('woff2');
          font-weight: 300;
          font-style: normal;
        }
        @font-face {
          font-family: 'ClashGrotesk';
          src: url(${ClashGroteskExtralight}) format('woff2');
          font-weight: 200;
          font-style: normal;
        }
      `,
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        },
      },
    },
  },
});

export default theme;
