import { useState, useEffect } from "react";
import { API_URL } from "../apiConfig";
import { useAuth } from "./useAuth";
import secureFetch from "./secureFetch";

const baseUrl = `${API_URL}/cart`;

const useCart = () => {
  const [cart, setCart] = useState([]);
  const { userDetails, refreshToken } = useAuth();
  // console.log("UseCart Cart:", cart)
  useEffect(() => {
    const fetchCart = async () => {
      if (!userDetails || Object.keys(userDetails).length === 0) {
        return;
      }
      try {
        const response = await secureFetch(`${baseUrl}`, {}, refreshToken);
        setCart(response.data); // Set cart from response.data
      } catch (error) {
        console.error("Error fetching cart", error);
        setCart([]); // Handle error by resetting cart
      }
    };

    fetchCart();
  }, [refreshToken, userDetails]);

  const addToCart = async (motifId, quantity) => {
    try {
      const response = await secureFetch(
        `${baseUrl}`,
        {
          method: "POST",
          data: { motif_id: motifId, quantity },
        },
        refreshToken
      );
      setCart(response.data.cart || []); // Update local cart state
    } catch (error) {
      console.error("Error adding to cart", error);
    }
  };

  // Update item quantity in the cart
  const updateCart = async (motifId, quantity) => {
    try {
      const response = await secureFetch(
        `${baseUrl}`,
        {
          method: "PUT",
          data: { motif_id: motifId, quantity },
        },
        refreshToken
      );
      setCart(response.data.cart || []);
    } catch (error) {
      console.error("Error updating cart", error);
    }
  };

  // Remove an item from the cart
  const removeFromCart = async (motifId) => {
    try {
      const response = await secureFetch(
        `${baseUrl}`,
        {
          method: "DELETE",
          data: { motif_id: motifId },
        },
        refreshToken
      );
      setCart(response.data.cart || []); // Update local cart state
    } catch (error) {
      console.error("Error removing from cart", error);
    }
  };

  // Clear the entire cart
  const clearCart = async () => {
    try {
      const response = await secureFetch(`${baseUrl}/clear`, { method: "DELETE" }, refreshToken);
      setCart([]); // Clear local cart state
    } catch (error) {
      console.error("Error clearing cart", error);
    }
  };

  return {
    cart,
    addToCart,
    updateCart,
    removeFromCart,
    clearCart,
  };
};

export default useCart;
