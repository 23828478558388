import { Box, Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ConstructionIcon from "@mui/icons-material/Construction";
import { useTranslation } from "react-i18next";

function ComingSoon() {
  const { t } = useTranslation();
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        textAlign: "center",
        margin: "auto",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <ConstructionIcon sx={{ fontSize: 60, color: "warning.main", mb: 2 }} />
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          {t("Dashboard.ComingSoon.Title")}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
          {t("Dashboard.ComingSoon.SubTitle")}
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/dashboard">
          {t("Dashboard.ComingSoon.GoDash")}
        </Button>
      </Box>
    </Paper>
  );
}

export default ComingSoon;
