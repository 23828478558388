import React from "react";
import { Grid, Typography, Paper, Container, Box } from "@mui/material";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import PrintIcon from "@mui/icons-material/Print";
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import { useTranslation } from "react-i18next";

const ServiceCard = ({ title, description, delay, IconComponent }) => (
  <Paper
    elevation={3}
    sx={{
      p: 4,
      textAlign: "center",
      minHeight: "300px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
    data-aos="fade-up"
    data-aos-delay={delay}
  >
    <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
      <IconComponent sx={{ fontSize: 100 }} />
    </Box>
    <Typography variant="h6" component="h3" sx={{ mb: 1 }}>
      {title}
    </Typography>
    <Typography variant="body2" component="p" fontSize={"1rem"}>
      {description}
    </Typography>
  </Paper>
);

const ServicesSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container
        component={"main"}
        display={"flex"}
        maxWidth={"xl"}
        sx={{ pb: 50, pt: 20 }}
      >
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={10} md={4}>
            <ServiceCard
              title={t("Home.Service.Card1.Title")}
              description={t("Home.Service.Card1.Desc")}
              delay="0"
              IconComponent={DesignServicesIcon}
            />
          </Grid>
          <Grid item xs={10} md={4}>
            <ServiceCard
              title={t("Home.Service.Card2.Title")}
              description={t("Home.Service.Card2.Desc")}
              delay="100"
              IconComponent={PrintIcon}
            />
          </Grid>
          <Grid item xs={10} md={4}>
            <ServiceCard
              title={t("Home.Service.Card3.Title")}
              description={t("Home.Service.Card3.Desc")}
              delay="200"
              IconComponent={EnergySavingsLeafIcon}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ServicesSection;
