import React, { useState, useEffect } from "react";
import { TextField, Typography, Grid, Paper, InputAdornment, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { Link as LinkIcon, LinkOff as LinkOffIcon } from "@mui/icons-material";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const PreviewContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  border: `2px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.background.half,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  height: "100%",
}));

const ImageDetails = ({ motifData, setMotifData }) => {
  const [locked, setLocked] = useState(true);
  const [lastModified, setLastModified] = useState(null);
  const {t} = useTranslation();
  const [details, setDetails] = useState(() => ({
    name: motifData.name || "",
    width: motifData.width || 0,
    height: motifData.height || 0,
    colors: motifData.colors || 1,
    area: 0,
  }));

  const updateMotifData = _.debounce((details) => {
    setMotifData({
      name: details.name,
      width: details.width,
      height: details.height,
      colors: details.colors,
    });
  }, 300);

  useEffect(() => {
    const setImageDimensions = async () => {
      if (motifData.imagePreviewUrl && (!motifData.width || !motifData.height)) {
        const img = new Image();
        img.onload = () => {
          const standardWidth = 100;
          const aspectRatio = img.naturalWidth / img.naturalHeight;
          const calculatedHeight = Math.round(standardWidth / aspectRatio);

          setDetails((prevDetails) => ({
            ...prevDetails,
            width: prevDetails.width || standardWidth,
            height: prevDetails.height || calculatedHeight,
            area: ((prevDetails.width || standardWidth) * (prevDetails.height || calculatedHeight)) / 100,
            aspectRatio: aspectRatio,
          }));
        };
        img.src = motifData.imagePreviewUrl;
      } else {
        setDetails((prevDetails) => ({
          ...prevDetails,
          area: (prevDetails.width * prevDetails.height) / 100,
        }));
      }
    };

    setImageDimensions();
  }, [motifData.imagePreviewUrl, motifData.width, motifData.height]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newValue = name === "name" ? value : parseFloat(value) || 0;
  
    if (name === "colors") {
      newValue = parseInt(value, 10) || 1;
    }
  
    setLastModified(name);
    setDetails((prevDetails) => {
      const newDetails = { ...prevDetails, [name]: newValue };
  
      if (locked && name !== "name" && name !== "colors") {
        if (name === "width" && prevDetails.aspectRatio) {
          const newHeight = Math.round(newValue / prevDetails.aspectRatio);
          newDetails.height = newHeight;
          newDetails.area = (newValue * newHeight) / 100;
        } else if (name === "height" && prevDetails.aspectRatio) {
          const newWidth = Math.round(newValue * prevDetails.aspectRatio);
          newDetails.width = newWidth;
          newDetails.area = (newWidth * newValue) / 100;
        }
      } else if (name !== "name") {
        newDetails.area = (newDetails.width * newDetails.height) / 100;
      }
  
      updateMotifData(newDetails);
  
      return newDetails;
    });
  };
  

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setMotifData(details);
      event.target.blur();
    }
  };

  const handleBlur = () => {
    setMotifData(details);
  };

  const formatArea = (area) => {
    if (area === Math.floor(area)) {
      return area.toString();
    }
    return area.toFixed(2);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={7}>
        <Typography variant="h6" sx={{ mb: 2 }}>
        {t("Dashboard.Motif.CategoryDetails")}
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9}>
            <TextField
              required
              label={t("Dashboard.Motif.Name")}
              name="name"
              value={details.name}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              onBlur={handleBlur}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={t("Dashboard.Motif.Colors")}
              name="colors"
              value={details.colors}
              onChange={handleChange}
              type="number"
              InputLabelProps={{ shrink: true }}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("Dashboard.Motif.Width")}
              name="width"
              value={details.width}
              onChange={handleChange}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              color="primary"
              sx={{ mt: 1 }}
              onClick={() => {
                setLocked(!locked);
                if (!locked) {
                  // If turning the lock back on, adjust the dimension not recently modified
                  setDetails((prevDetails) => {
                    if (lastModified === "height") {
                      // If height was last modified, adjust width
                      const newWidth = Math.round(prevDetails.height * prevDetails.aspectRatio);
                      return {
                        ...prevDetails,
                        width: newWidth,
                        area: (newWidth * prevDetails.height) / 100,
                      };
                    } else if (lastModified === "width") {
                      // If width was last modified, adjust height
                      const newHeight = Math.round(prevDetails.width / prevDetails.aspectRatio);
                      return {
                        ...prevDetails,
                        height: newHeight,
                        area: (prevDetails.width * newHeight) / 100,
                      };
                    }
                    return prevDetails;
                  });
                }
              }}
            >
              {locked ? <LinkIcon /> : <LinkOffIcon />}
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("Dashboard.Motif.Height")}
              name="height"
              value={details.height}
              onChange={handleChange}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={t("Dashboard.Motif.Area")}
              value={formatArea(details.area)}
              InputProps={{
                readOnly: true,
                endAdornment: <InputAdornment position="end">cm²</InputAdornment>,
              }}
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        <PreviewContainer>
          {motifData.imagePreviewUrl ? (
            <img
              src={motifData.imagePreviewUrl}
              alt="Uploaded"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
                borderRadius: 8,
              }}
            />
          ) : (
            <Typography variant="body1" color="textSecondary">
              {t("Dashboard.Motif.NoUpload")}
            </Typography>
          )}
        </PreviewContainer>
      </Grid>
    </Grid>
  );
};

export default ImageDetails;
