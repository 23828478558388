// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "ClashGrotesk", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAGA;EACE,uCAAA;AAFF","sourcesContent":["// $primary-color: #ff4400;\n// $secondary-color: #00ff44;\n\nbody {\n  font-family: \"ClashGrotesk\", sans-serif;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
