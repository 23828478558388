import React, { useState } from "react";
import { Box, Typography, IconButton, Paper, Grid, Slider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const UploadContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'dragActive' // Prevent `dragActive` from being forwarded to the DOM
})(({ theme, dragActive }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  border: `2px dashed ${theme.palette.primary.main}`,
  backgroundColor: dragActive ? theme.palette.action.hover : theme.palette.background.default,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
}));

const ImagePreviewContainer = styled(Box)(({ theme, bgcolor }) => ({
  position: "relative",
  textAlign: "center",
  padding: theme.spacing(2),
  border: `2px solid ${theme.palette.primary.main}`,
  backgroundColor: bgcolor,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
}));

const ImageUpload = ({ motifData, setMotifData }) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [bgColor, setBgColor] = useState("rgb(255, 255, 255)");
  const [dragactive, setDragActive] = useState(false);

  const handleImageFile = (file) => {
    const fileExtension = file.name.split(".").pop();
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setMotifData({
          ...motifData,
          image: file,
          imagePreviewUrl: e.target.result,
          name: file.name.replace(/\.[^/.]+$/, ""),
          size: (file.size / 1024).toFixed(2) + " KB",
          extension: fileExtension,
          dimensions: `${img.width} x ${img.height}`,
        });
      };
    };
    reader.readAsDataURL(file);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      handleImageFile(event.target.files[0]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleImageFile(event.dataTransfer.files[0]);
    }
  };

  const handleRemoveImage = () => {
    setMotifData({ ...motifData, image: null, imagePreviewUrl: null });
  };

  const handleSliderChange = (event, newValue) => {
    const greyValue = 255 - Math.round((newValue * 255) / 100);
    const greyColor = `rgb(${greyValue}, ${greyValue}, ${greyValue})`;
    setBgColor(greyColor);
  };

  return (
    <Box sx={{ mt: 4 }}>
      {motifData.image ? (
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Typography variant="h5">{motifData.name}</Typography>
            <ImagePreviewContainer bgcolor={bgColor}>
              <img
                src={motifData.imagePreviewUrl}
                alt="Uploaded"
                style={{
                  maxWidth: "100%",
                  maxHeight: "50vh",
                  objectFit: "contain",
                  borderRadius: theme.shape.borderRadius,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Slider
                  min={0}
                  max={100}
                  defaultValue={0}
                  aria-labelledby="background-slider"
                  valueLabelDisplay="auto"
                  onChange={handleSliderChange}
                  sx={{
                    width: 120,
                    mr: 2,
                    "& .MuiSlider-track": {
                      bgcolor: (value) =>
                        `rgb(${255 - Math.round((value * 255) / 100)}, ${255 - Math.round((value * 255) / 100)}, ${
                          255 - Math.round((value * 255) / 100)
                        })`,
                    },
                    "& .MuiSlider-thumb": {
                      bgcolor: (value) =>
                        `rgb(${255 - Math.round((value * 255) / 100)}, ${255 - Math.round((value * 255) / 100)}, ${
                          255 - Math.round((value * 255) / 100)
                        })`,
                    },
                  }}
                />
                <IconButton
                  onClick={handleRemoveImage}
                  sx={{
                    backgroundColor: theme.palette.error.main,
                    color: "white",
                    "&:hover": {
                      backgroundColor: theme.palette.error.dark,
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </ImagePreviewContainer>
            <Box display="flex" width="100%" justifyContent="space-between" sx={{ px: "5px" }}>
              <Typography variant="body1" color="textSecondary">
              {t("Dashboard.Motif.Dimensions")}: {motifData.dimensions}
              </Typography>
              <Typography variant="body2" color="textSecondary">
              {t("Dashboard.Motif.Size")}: {motifData.size}
              </Typography>
              <Typography variant="body2" color="textSecondary">
              {t("Dashboard.Motif.FileExtension")}: {motifData.extension}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <UploadContainer
          dragActive={dragactive}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            accept=".jpg,.jpeg,.png,.svg"
            style={{ display: "none" }}
            id="upload-button-file"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="upload-button-file" style={{ cursor: "pointer", display: "block" }}>
            <CloudUploadIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
            <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
            {dragactive ? t("Dashboard.Motif.DragImgShort"): t("Dashboard.Motif.DragImg")}
            </Typography>
          </label>
        </UploadContainer>
      )}
    </Box>
  );
};

export default ImageUpload;
