import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import gsap from "gsap";

const Wave = ({
  amplitude = 50,
  duration = 4,
  fillStyle = "rgba(103,58,183,0.8)",
  frequency = 2.5,
  segments = 100,
  waveHeightRatio = 0.25,
  backgroundColor = "transparent",
}) => {
  const canvasRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !boxRef.current) return;

    const context = canvas.getContext("2d");
    let resolution = window.devicePixelRatio || 1;
    let waves = [];
    let resized = false;

    function resizeCanvas() {
      if (!boxRef.current) return; // Exit if boxRef is null
      const boxWidth = boxRef.current.clientWidth;
      const boxHeight = boxRef.current.clientHeight;

      canvas.width = boxWidth * resolution;
      canvas.height = boxHeight * resolution;

      canvas.style.width = boxWidth + "px";
      canvas.style.height = boxHeight + "px";

      context.scale(resolution, resolution);
    }

    function createWave(context, options) {
      options = options || {};

      let wave = {
        amplitude: options.amplitude || 200,
        context: context,
        curviness: options.curviness || 0.75,
        duration: options.duration || 2,
        fillStyle: options.fillStyle,
        frequency: options.frequency || 4,
        height: options.height || 600,
        points: [],
        segments: options.segments || 100,
        tweens: [],
        waveHeight: options.waveHeight || 300,
        width: options.width || 800,
        x: options.x || 0,
        y: options.y || 0,

        init: init,
        resize: resize,
        draw: draw,
        kill: kill
      };

      init();

      function kill() {
        let tweens = wave.tweens;
        let len = tweens.length;
        for (let i = 0; i < len; i++) {
          tweens[i].kill();
        }
        tweens.length = 0;
        wave.points.length = 0;
      }

      function init() {
        kill();

        let segments = wave.segments;
        let interval = wave.width / segments;

        for (let i = 0; i <= segments; i++) {
          let norm = i / segments;
          let point = {
            x: wave.x + i * interval,
            y: 1
          };

          let tween = gsap.to(point, {
            duration: wave.duration,
            y: -1,
            repeat: -1,
            yoyo: true,
            ease: "sine.inOut"
          }).progress(norm * wave.frequency);

          wave.tweens.push(tween);
          wave.points.push(point);
        }
      }

      function draw() {
        let points = wave.points;
        let len = points.length;
        let startY = wave.waveHeight;
        let height = wave.amplitude / 2;

        context.beginPath();
        context.moveTo(points[0].x, startY + points[0].y * height);

        for (let i = 1; i < len; i++) {
          let point = points[i];
          context.lineTo(point.x, startY + point.y * height);
        }

        context.lineTo(wave.x + wave.width, wave.y + wave.height);
        context.lineTo(wave.x, wave.y + wave.height);
        context.closePath();
        context.fillStyle = wave.fillStyle;
        context.fill();
      }

      function resize(width, height) {
        wave.width = width;
        wave.height = height;

        let points = wave.points;
        let len = points.length;
        let interval = wave.width / wave.segments;

        for (let i = 0; i < len; i++) {
          let point = points[i];
          point.x = wave.x + i * interval;
        }
      }

      return wave;
    }

    resizeCanvas();

    const boxWidth = boxRef.current.clientWidth;
    const boxHeight = boxRef.current.clientHeight;

    let wave1 = createWave(context, {
      amplitude: amplitude,
      duration: duration,
      fillStyle: fillStyle,
      frequency: frequency,
      width: boxWidth,
      height: boxHeight,
      segments: segments,
      waveHeight: boxHeight * waveHeightRatio
    });

    waves.push(wave1);

    gsap.to(waves, {
      duration: 10,
      waveHeight: boxHeight / 2,
      ease: "sine.inOut",
      repeat: -1,
      repeatDelay: 1,
      yoyo: true
    });

    gsap.to(wave1, {
      duration: 6,
      amplitude: 10,
      ease: "sine.inOut",
      repeat: -1,
      yoyo: true
    });

    window.addEventListener("resize", () => {
      resized = true;
    });

    gsap.ticker.add(update);

    function update() {
      if (!boxRef.current) return; // Exit if boxRef is null
      let len = waves.length;

      if (resized) {
        resizeCanvas();
        for (let i = 0; i < len; i++) {
          waves[i].resize(boxRef.current.clientWidth, boxRef.current.clientHeight);
        }
        resized = false;
      }

      context.clearRect(0, 0, boxRef.current.clientWidth, boxRef.current.clientHeight);
      context.globalCompositeOperation = "soft-light";

      for (let i = 0; i < len; i++) {
        waves[i].draw();
      }
    }
  }, [amplitude, duration, fillStyle, frequency, segments, waveHeightRatio]);

  return (
    <Box ref={boxRef} position="relative" width="100%" height="200px" overflow="hidden" bgcolor={backgroundColor}>
      <canvas ref={canvasRef} id="canvas"></canvas>
    </Box>
  );
};

export default Wave;
