import { useState, useCallback } from "react";
import { API_URL } from "../apiConfig";
import secureFetch from "./secureFetch";

const baseUrl = `${API_URL}/user`;

export function useUser() {
  const [user, setUser] = useState(() => {
    const cachedUser = localStorage.getItem("userDetails");
    return cachedUser ? JSON.parse(cachedUser) : null;
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getUserByID = useCallback(async (userId) => {
    setLoading(true);
    setError(null);

    try {
      let userData = localStorage.getItem(`userDetails_${userId}`);
      if (userData) {
        userData = JSON.parse(userData);
      } else {
        const response = await secureFetch(`${baseUrl}/${userId}`, {});

        // Cache user data in local storage
        // localStorage.setItem(`userDetails_${userId}`, JSON.stringify(response.data));
        userData = response.data;
      }

      setUser(userData);
    } catch (error) {
      setError(error.message);
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateUserProfile = useCallback(async (userId, updatedData) => {
    setLoading(true);
    setError(null);
    // console.log(updatedData)
    try {
      const response = await secureFetch(`${baseUrl}/${userId}`, {
        method: 'PUT',
        data: updatedData,
      });
  
      const updatedUser = response.data;
      // Optionally update the cached user data
      localStorage.setItem(`userDetails_${userId}`, JSON.stringify(updatedUser));
      setUser(updatedUser);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    user,
    loading,
    error,
    getUserByID,
    updateUserProfile,
  };
}
