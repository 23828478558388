import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

function AdminPage() {
  const { userDetails } = useAuth();
  const isAdmin = ["admin", "superadmin"].includes(userDetails.accesslevel);

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default AdminPage;

