import React, { useState } from "react";
import { Paper, Box, Stepper, Step, StepLabel, IconButton, stepLabelClasses } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ImageDetails from "../../../components/motif/imageDetails";
import ReviewSubmit from "../../../components/motif/reviewSubmit";
import ImageUpload from "../../../components/motif/imageUpload";
import { useMotifs } from "../../../hooks/useMotifs";
import { useTranslation } from "react-i18next";

const CreateMotif = () => {
  const { createMotif } = useMotifs();
  const [activeStep, setActiveStep] = useState(0);
  const [furthestStep, setFurthestStep] = useState(0);
  const [motifData, setMotifData] = useState({
    image: null,
    name: "",
    width: "",
    height: "",
    colors: 1,
    locked: true,

  });
  const { t } = useTranslation();
  const steps = [
    t("Dashboard.Motif.CategoryUpload"),
    t("Dashboard.Motif.CategoryDetails"),
    t("Dashboard.Motif.CategoryReview"),
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const nextStep = prevActiveStep + 1;
      setFurthestStep((prevFurthestStep) => Math.max(prevFurthestStep, nextStep));
      return nextStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    if (step <= furthestStep) {
      setActiveStep(step);
    }
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setMotifData({
  //     image: null,
  //     name: "",
  //     width: "",
  //     height: "",
  //   });
  // };

  const handleMotifDataChange = (newData) => {
    // console.log("Updating motif data with:", newData);
    setMotifData((prevData) => {
      const updatedData = { ...prevData, ...newData };
      // console.log("Previous data:", prevData, "New data:", updatedData);
      return updatedData;
    });
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <ImageUpload motifData={motifData} setMotifData={handleMotifDataChange} />;
      case 1:
        return <ImageDetails motifData={motifData} setMotifData={handleMotifDataChange} />;
      case 2:
        return <ReviewSubmit createMotif={createMotif} motifData={motifData} />;
      default:
        return "Unknown step";
    }
  };

  const isNextButtonDisabled = () => {
    if (activeStep === 0 && !motifData.image) {
      return true;
    } else if (activeStep === 1 && (!motifData.name || !motifData.width || !motifData.height)) {
      return true;
    }
    return false;
  };

  return (
    <Paper
      elevation={0}
      sx={{ padding: 2, minHeight: "75vh", display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ width: "100%" }}>
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} onClick={() => handleStepChange(index)} sx={{ cursor: "pointer" }}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    cursor: index <= furthestStep ? "pointer" : "default",
                    color: index <= furthestStep ? "primary.dark" : "grey.500",
                    "& .MuiStepIcon-text": {
                      fill: index <= furthestStep ? "common.white" : "grey.500",
                    },
                  },
                }}
                sx={{
                  [`& .${stepLabelClasses.label}`]: {
                    color: index <= furthestStep ? "primary.dark" : "grey.500",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 2, mb: 1, flexGrow: 1 }}>{renderStepContent(activeStep)}</Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: "space-between" }}>
          <IconButton
            color="primary"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              borderRadius: "50%",
              p: 1,
              backgroundColor: activeStep === 0 ? "grey.500" : "primary.main",
              color: "common.white",
              "&:hover": {
                backgroundColor: activeStep === 0 ? "grey.500" : "primary.dark",
              },
              fontSize: "1.5rem",
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep !== steps.length - 1 && (
            <IconButton
              color="primary"
              onClick={handleNext}
              disabled={isNextButtonDisabled()}
              sx={{
                borderRadius: "50%",
                p: 1,
                backgroundColor: isNextButtonDisabled() ? "grey.500" : "primary.main",
                color: "common.white",
                "&:hover": {
                  backgroundColor: isNextButtonDisabled() ? "grey.500" : "primary.dark",
                },
                fontSize: "1.5rem",
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default CreateMotif;
