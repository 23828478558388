import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { orange } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../styles/theme";
import { useTranslation } from "react-i18next";

// Define a keyframes animation for rotating the gradient
const rotateGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const GradientText = styled("span")(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.secondary.main}, ${orange[500]})`,
  backgroundSize: "200% 200%",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  display: "inline-block",
  animation: `${rotateGradient} 5s linear infinite`, 
}));

const Hero = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box paddingTop={"200px"} height={"800px"}>
      <Typography
        variant="h4"
        component="span"
        data-aos="fade-up"
        style={{ color: "#333", fontWeight: "500", display: "block" }}
      >
        Viktig å ha ett godt
      </Typography>
      <Typography
        variant="h1"
        data-aos="fade-up"
        data-aos-duration="800"
        fontSize={isMobile ? "11vw" : "140px"}
        style={{ fontWeight: "bold", display: "block" }}
      >
        <GradientText>Førsteinntrykk</GradientText>
      </Typography>

      <Typography
        variant="h6"
        component="p"
        style={{ color: "#333", marginBottom: "10px" }}
        data-aos="fade-up"
        data-aos-delay="300"
      >
        {t("Home.Hero.Slogan")}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ width: "200px", marginBottom: "50px", marginTop: "20px" }}
        data-aos="fade-up"
        data-aos-delay="400"
        component={Link} to="/login"
      >
        {t("LogIn")}
      </Button>
    </Box>
  );
};

export default Hero;
