import { useState, useEffect } from "react";
import { useAuth } from "./../useAuth";
import { API_URL } from "../../apiConfig";
import secureFetch from "../secureFetch";

const baseUrl = `${API_URL}/color`;

export const useColorPricing = () => {
  const { refreshToken } = useAuth();
  const [colorPricing, setColorPricing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchColorPricingRules();
  }, []);

  const fetchColorPricingRules = async () => {
    setLoading(true);
    try {
      const response = await secureFetch(baseUrl, {}, refreshToken);
      setColorPricing(response.data);
    } catch (err) {
      setError(err.message || "Error fetching color pricing rules");
    } finally {
      setLoading(false);
    }
  };

  const createOrUpdateColorPricingRule = async (colorPricingData) => {
    setLoading(true);
    try {
      const response = await secureFetch(
        baseUrl,
        {
          method: "POST",
          data: colorPricingData,
        },
        refreshToken
      );
      fetchColorPricingRules(); // Refresh the list after updating
      return response.data;
    } catch (err) {
      setError(err.message || "Error creating/updating color pricing rule");
    } finally {
      setLoading(false);
    }
  };

  const deleteColorPricingRule = async (id) => {
    setLoading(true);
    try {
      await secureFetch(`${baseUrl}/${id}`, {
        method: 'DELETE',
      }, refreshToken);
      fetchColorPricingRules(); // Refresh the list after deletion
    } catch (err) {
      setError(err.message || 'Error deleting color pricing rule');
    } finally {
      setLoading(false);
    }
  };

  return {
    colorPricing,
    loading,
    error,
    fetchColorPricingRules,
    createOrUpdateColorPricingRule,
    deleteColorPricingRule,
  };
};
