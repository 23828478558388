import { useState, useCallback, useEffect } from "react";
import secureFetch from "./secureFetch";
import { useAuth } from "./useAuth";
import { API_URL } from "../apiConfig";

const baseUrl = `${API_URL}/admin`;

export function useAdmin() {
  const { refreshToken, userDetails } = useAuth();
  const [motifs, setMotifs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isAdmin = userDetails?.accesslevel === "admin" || userDetails?.accesslevel === "superadmin";

  // Get All Motifs
  const fetchAllMotifs = useCallback(async () => {
    if (!isAdmin) {
      setError("You do not have permission to fetch motifs.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await secureFetch(`${baseUrl}/motif`, {}, refreshToken);
      setMotifs(response.data);
    } catch (err) {
      console.error(err);
      setError(err.message || "Something went wrong");
    }
    setLoading(false);
  }, [refreshToken]);

  useEffect(() => {
    fetchAllMotifs();
  }, [fetchAllMotifs]);

  // Get All Motifs
  const approveMotif = async (id) => {
    if (!isAdmin) {
      setError("You do not have permission to approve motifs.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await secureFetch(
        `${baseUrl}/motif/${id}/approve`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        },
        refreshToken
      );
      setMotifs(response.data);
    } catch (err) {
      console.error(err);
      setError(err.message || "Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllMotifs();
  }, [fetchAllMotifs]);

  return { motifs, loading, error, fetchAllMotifs, approveMotif };
}
