import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Menu, MenuItem, Divider } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PercentIcon from "@mui/icons-material/Percent";
import { useTranslation } from "react-i18next";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.text.secondary,
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: theme.palette.action.selectedOpacity,
      },
    },
  },
}));

const UserProfileMenu = ({ anchorEl, handleOpen, handleClose }) => {
  const isLoggedIn = Boolean(localStorage.getItem("accessToken"));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    handleClose();
    navigate("/login");
  };

  return (
    <StyledMenu
      id="profile-menu"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {isLoggedIn
        ? [
            <MenuItem key="dashboard" onClick={handleClose} component={Link} to="/dashboard/motif">
              <DashboardIcon />
              {t('Dashboard.Label')}
            </MenuItem>,
            <MenuItem
              key="calculator"
              onClick={handleClose}
              component={Link}
              to="/dashboard/calculator"
            >
              <PercentIcon />
              {t('Dashboard.PriceCalculator.Label')}
            </MenuItem>,
            <MenuItem
              key="settings"
              onClick={handleClose}
              component={Link}
              to="/dashboard/settings"
            >
              <SettingsIcon />
              {t('Dashboard.Settings.Label')}
            </MenuItem>,
            <Divider key="divider" />,
            <MenuItem key="logout" onClick={handleLogout}>
              <LogoutIcon />
              {t('LogOut')}
            </MenuItem>,
          ]
        : [
            <MenuItem key="login" onClick={handleClose} component={Link} to="/login">
              <LoginIcon />
              {t('LogIn')}
            </MenuItem>,
            <MenuItem key="signup" onClick={handleClose} component={Link} to="/signup">
              <PersonAddIcon />
              {t('SignUp')}
            </MenuItem>,
          ]}
    </StyledMenu>
  );
};

export default UserProfileMenu;
