import { useState, useEffect, useCallback } from "react";
import secureFetch from "../secureFetch";
import { useAuth } from "../useAuth";
import { API_URL } from "../../apiConfig";

const baseUrl = `${API_URL}/pricechart`;

const formatData = (data) => {
  return data.map((item) => ({
    id: item.id,
    range: item.range,
    prices: Object.keys(item).reduce((acc, key) => {
      if (key.startsWith("size_")) {
        const sizeLabel = key.split("_")[1];
        acc[sizeLabel] = item[key];
      }
      return acc;
    }, {}),
  }));
};

const usePriceChart = () => {
  const { userDetails, refreshToken } = useAuth();
  const [priceCharts, setPriceCharts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPriceCharts = useCallback(async () => {
    if (!userDetails || Object.keys(userDetails).length === 0) {
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await secureFetch(`${baseUrl}`, {}, refreshToken);
      setPriceCharts(formatData(response.data));
    } catch (err) {
      console.error("Error fetching price charts:", err);
      setError(`Failed to fetch data: ${err.message || err.toString()}`);
    } finally {
      setLoading(false);
    }
  }, [refreshToken]);

  const updatePriceChartBatch = async (updates) => {
    // console.log("Updates:", updates);
    const formattedUpdates = updates.map((item) => {
      const formattedPrices = {};
      Object.entries(item.prices).forEach(([key, value]) => {
        formattedPrices[`size_${key}`] = value;
      });
      return { id: item.id, prices: formattedPrices };
    });

    // console.log(formattedUpdates);

    try {
      const response = await secureFetch(
        `${baseUrl}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({ updates: formattedUpdates }),
        },
        refreshToken
      );
      await fetchPriceCharts(); // Refresh the data
    } catch (err) {
      console.error("Error updating price chart:", err);
      setError(`Update failed: ${err.message || err.toString()}`);
    }
  };

  useEffect(() => {
    fetchPriceCharts();
  }, [fetchPriceCharts]); // Ensure fetchPriceCharts is stable

  return { priceCharts, loading, error, updatePriceChartBatch };
};

export default usePriceChart;
