import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Menu,
  MenuItem,
  Container,
  Badge,
} from "@mui/material";
import UserProfileMenu from "./userProfileMenu";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import logo from "../../../assets/images/ForsteTextLogo2.png";
import mobileLogo from "../../../assets/images/ForsteLogo.svg";
import { CartContext } from "../../../context/CartContext";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [anchorNav, setAnchorNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { cart } = useContext(CartContext);
  const { t } = useTranslation();

  const navigationLinks = [
    { name: t("Header.Products"), to: "/products" },
    { name: t("Header.About"), to: "/about" },
    { name: t("Header.Contact"), to: "/contact" },
  ];

  const openNav = (event) => {
    setAnchorNav(event.currentTarget);
  };
  const closeNav = () => {
    setAnchorNav(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const renderNavLinks = () =>
    navigationLinks.map((link) => (
      <Button
        key={link.name}
        color="inherit"
        sx={{ textTransform: "none", fontWeight: "400", mx: 1 }}
        component={Link}
        to={link.to}
      >
        {link.name}
      </Button>
    ));

  return (
    <AppBar position="sticky" color="appBar" elevation={0} sx={{ boxShadow: "none" }}>
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative", // Ensure the logo is centered within the toolbar
          }}
        >
          {/* Navigation Links and Menu */}
          <Box sx={{ display: "flex", alignItems: "center", zIndex: 1 }}>
            {isMobile && (
              <>
                <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={openNav}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorNav}
                  open={Boolean(anchorNav)}
                  onClose={closeNav}
                >
                  {navigationLinks.map((link) => (
                    <MenuItem key={link.name} onClick={closeNav} component={Link} to={link.to}>
                      {link.name}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
            {!isMobile && renderNavLinks()}
          </Box>

          {/* Centered Logo */}
          <Box
            sx={{
              position: "absolute", // Keep the logo centered
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link to="/" style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={isMobile ? mobileLogo : logo} // Conditional logo
                alt="Første Inntrykk Logo"
                style={{
                  height: "35px",
                  width: "auto",
                }}
              />
            </Link>
          </Box>

          {/* User Profile and Cart Icons */}
          <Box sx={{ display: "flex", alignItems: "center", ml: "auto", zIndex: 1 }}>
            <Badge badgeContent={cart.length} color="primary" overlap="circular">
              <IconButton color="inherit" component={Link} to="/checkout">
                <LocalMallOutlinedIcon />
              </IconButton>
            </Badge>

            <IconButton color="inherit" onClick={handleProfileMenuOpen}>
              <PersonRoundedIcon />
            </IconButton>
            <UserProfileMenu
              anchorEl={anchorEl}
              handleOpen={handleProfileMenuOpen}
              handleClose={handleProfileMenuClose}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
