import React, { useEffect, useState } from "react";
import { initialPricingData } from "../../../../components/admin/PricingData";
import usePriceChart from "../../../../hooks/Price/usePriceChart";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Typography,
  InputAdornment,
  Grid,
  Button,
  Alert,
  Skeleton,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import usePricingRules from "../../../../hooks/Price/usePriceRule";
import { useTranslation } from "react-i18next";
import { useColorPricing } from "../../../../hooks/Price/useColorPricing";

function PriceChart() {
  const { t } = useTranslation();
  const {
    priceCharts,
    updatePriceChartBatch,
    loading: priceChartLoading,
    error: priceChartError,
  } = usePriceChart();

  const { pricingRules, fetchPricingRules, updatePricingRule } = usePricingRules();

  const {
    colorPricing,
    fetchColorPricingRules,
    createOrUpdateColorPricingRule,
    deleteColorPricingRule,
  } = useColorPricing();

  const [adjustedData, setAdjustedData] = useState([]);
  const [lumpSum, setLumpSum] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [currentPercentage, setCurrentPercentage] = useState(0);
  const [percentageRule, setPercentageRule] = useState(null);
  const [lumpSumRule, setLumpSumRule] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    fetchPricingRules();
    fetchColorPricingRules();
  }, []);

  useEffect(() => {
    setPercentageRule(pricingRules.find((rule) => rule.rule_type === "price_chart_percentage"));
    setLumpSumRule(pricingRules.find((rule) => rule.rule_type === "lump_sum"));
  }, [pricingRules]);

  useEffect(() => {
    if (percentageRule && lumpSumRule) {
      setLumpSum(lumpSumRule.value);
      setCurrentPercentage(percentageRule.value);
      setAdjustedData(priceCharts);
    }
  }, [percentageRule, lumpSumRule, priceCharts]);

  const handleLumpSumChange = (event) => {
    setLumpSum(event.target.value);
  };

  const handlePercentageChange = (e) => {
    const newPercentage = parseFloat(e.target.value);
    setPercentage(newPercentage);

    const newAdjustedData = priceCharts.map((item) => {
      const newPrices = {};
      Object.entries(item.prices).forEach(([size]) => {
        newPrices[size] = (parseFloat(item.prices[size]) * (1 + newPercentage / 100)).toFixed(2);
      });
      return { ...item, prices: newPrices };
    });
    setAdjustedData(newAdjustedData);
  };

  const handleReset = () => {
    const newAdjustedData = adjustedData.map((item) => {
      const matchingInitial = initialPricingData.find((initial) => initial.range === item.range);
      if (matchingInitial) {
        return { ...item, prices: { ...matchingInitial.prices } };
      }
      return item;
    });
    setAdjustedData(newAdjustedData);
    setPercentage(0);
    setCurrentPercentage(0);
  };

  const handleSave = async () => {
    updatePriceChartBatch(adjustedData);

    if (lumpSumRule) {
      await updatePricingRule(lumpSumRule.rule_id, lumpSum);
    }
    if (percentageRule) {
      await updatePricingRule(percentageRule.rule_id, currentPercentage + percentage);
      setCurrentPercentage(currentPercentage + percentage);
    }

    setPercentage(0);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleAddNewColorPricing = async () => {
    // Get the last color value and percentage increase
    const lastColorPricing =
      colorPricing.length > 0
        ? colorPricing[colorPricing.length - 1]
        : { colors: 0, percentage_increase: 0 };

    // Mark the previous latest as non-default
    if (lastColorPricing.id) {
      await createOrUpdateColorPricingRule({
        ...lastColorPricing,
        is_default: false,
      });
    }

    // Create new color pricing with the next color value, same percentage increase, and set as default
    createOrUpdateColorPricingRule({
      colors: lastColorPricing.colors + 1, // Increment the last color by 1
      percentage_increase: lastColorPricing.percentage_increase, // Use the same percentage increase as the last color
      is_default: true, // Set the new rule as the default
    });
  };

  const handleDeleteColorPricingRule = async (ruleId) => {
    await deleteColorPricingRule(ruleId);

    // Check if the deleted rule was the default one
    if (ruleId === latestColorPricingId) {
      // Set the previous rule as default
      const newLatestColorPricing =
        colorPricing.length > 1 ? colorPricing[colorPricing.length - 2] : null;

      if (newLatestColorPricing) {
        await createOrUpdateColorPricingRule({
          ...newLatestColorPricing,
          is_default: true,
        });
      }
    }
  };

  if (priceChartLoading) {
    return (
      <Paper elevation={0} sx={{ padding: 2, overflowX: "auto" }}>
        <Skeleton variant="rectangular" height={40} width={120} />
        <Skeleton variant="rectangular" height={40} width={200} sx={{ mt: 2 }} />
        <Skeleton variant="rectangular" height={400} sx={{ mt: 2 }} />
      </Paper>
    );
  }

  if (priceChartError) return <Alert severity="error">{priceChartError}</Alert>;
  if (adjustedData.length === 0)
    return <Alert severity="warning">{t("Dashboard.PriceList.NoData")}</Alert>;

  const latestColorPricingId =
    colorPricing.length > 0 ? colorPricing[colorPricing.length - 1].id : null;

  return (
    <Paper elevation={0} sx={{ padding: 2, overflowX: "auto" }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="price chart tabs"
        style={{ marginBottom: 15 }}
      >
        <Tab label={t("Dashboard.PriceList.ColorPricing")} />
        <Tab label={t("Dashboard.PriceList.PriceChart")} />
      </Tabs>

      {tabIndex === 1 && (
        <>
          <Grid container spacing={2} py={1} alignItems={"center"}>
            <Grid item xs={12} md={3} lg={3}>
              <TextField
                label={t("Dashboard.PriceList.LumpSum")}
                value={lumpSum}
                onChange={handleLumpSumChange}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">NOK</InputAdornment>,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} lg={5}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    label={t("Dashboard.PriceList.CurrentPerc")}
                    type="number"
                    disabled
                    value={currentPercentage}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t("Dashboard.PriceList.AdjustPerc")}
                    type="number"
                    value={percentage}
                    onChange={handlePercentageChange}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                <Button variant="outlined" color="primary" onClick={handleReset}>
                  {t("Dashboard.PriceList.Reset")}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  {t("Dashboard.PriceList.Save")}
                </Button>
              </Box>
            </Grid>
          </Grid>

          <TableContainer sx={{ maxHeight: "500px", overflowY: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "110px" }}>
                    <Typography variant="subtitle2">
                      {t("Dashboard.PriceList.Size")} \ {t("Dashboard.PriceList.Quantity")}
                    </Typography>
                  </TableCell>
                  {Object.keys(adjustedData[0]?.prices).map((size) => (
                    <TableCell key={size} align="center" padding="none">
                      <Typography variant="subtitle2">{size}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {adjustedData.map((row) => (
                  <TableRow key={row.range}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">{row.range}</Typography>
                    </TableCell>
                    {Object.keys(row.prices).map((size) => (
                      <TableCell key={`${row.range}-${size}`} align="left" padding="none">
                        <TextField
                          value={row.prices[size]}
                          type="number"
                          inputProps={{
                            readOnly: true,
                            style: { textAlign: "center", padding: 0, height: "auto" },
                          }}
                          sx={{
                            width: "70px",
                            "& .MuiInputBase-root": {
                              fontSize: "0.9rem",
                              padding: ".8rem .25rem",
                              height: "auto",
                            },
                            "& input": { padding: "0 0" },
                          }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {tabIndex === 0 && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t("Dashboard.PriceList.ColorPricing")}
          </Typography>
          {colorPricing.map((rule) => (
            <Grid container spacing={2} key={rule.id} alignItems="center" sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <TextField
                  label={`${t("Dashboard.PriceList.Colors")}: ${rule.colors}`}
                  value={rule.percentage_increase}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  fullWidth
                  onChange={(e) =>
                    createOrUpdateColorPricingRule({
                      ...rule,
                      percentage_increase: parseFloat(e.target.value),
                    })
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={rule.id !== latestColorPricingId}
                  onClick={() => handleDeleteColorPricingRule(rule.id)}
                >
                  {t("Dashboard.PriceList.Delete")}
                </Button>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddNewColorPricing}
            sx={{ mt: 2 }}
          >
            {t("Dashboard.PriceList.AddNewColor")}
          </Button>
        </Box>
      )}
    </Paper>
  );
}

export default PriceChart;
