import React, { useState } from "react";
import { Box, Typography, Button, Grid, Paper, Alert, Snackbar } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PreviewContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  border: `2px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.background.half,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  height: "100%",
}));

const ReviewSubmit = ({ motifData, createMotif }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const {t} = useTranslation();

  const handleSubmit = async () => {
    const submitData = {
      name: motifData.name,
      width: motifData.width,
      height: motifData.height,
      image_url: motifData.image || "https://placehold.co/600x400",
      colors: motifData.colors,
    };

    // console.log("Submitting motif data:", submitData);
    try {
      const result = await createMotif(submitData);
      setAlertMessage(t("Dashboard.Motif.MotifSuccess"));
      setAlertSeverity("success");
      setOpen(true);
      setTimeout(() => navigate(`/dashboard/motif/${result.id}`), 2000); // Redirect after 2 seconds
    } catch (error) {
      console.error(t("Dashboard.Motif.MotifFailed"),':', error);
      setAlertMessage(`${t("Dashboard.Motif.MotifFailed")}: ${error.message}`);
      setAlertSeverity("error");
      setOpen(true);
    }
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid container spacing={4} justifyContent="space-between" alignItems="start">
        <Grid item xs={12} sm={7}>
          <Typography variant="h6" sx={{ mb: 2 }}>
          {t("Dashboard.Motif.CategoryReview")}
          </Typography>
          <Box sx={{ p: 2, border: "1px solid #e0e0e0", borderRadius: "4px", mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography textAlign={"right"} variant="subtitle1" color={"grey"}>
                {t("Dashboard.Motif.Name")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography noWrap variant="subtitle1">
                  {motifData.name}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography textAlign={"right"} variant="subtitle1" color={"grey"}>
                {t("Dashboard.Motif.Color")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography noWrap variant="subtitle1">
                  {motifData.colors}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography textAlign={"right"} variant="subtitle1" color={"grey"}>
                {t("Dashboard.Motif.Width")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">{motifData.width} mm</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography textAlign={"right"} variant="subtitle1" color={"grey"}>
                {t("Dashboard.Motif.Height")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">{motifData.height} mm</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2, px: 4, py: 1 }}>
            {t("Dashboard.Motif.Upload")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <PreviewContainer>
            {motifData.imagePreviewUrl ? (
              <img
                src={motifData.imagePreviewUrl}
                alt="Uploaded"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  borderRadius: 8,
                }}
              />
            ) : (
              <Typography variant="body1" color="textSecondary">
                {t("Dashboard.Motif.NoUpload")}
              </Typography>
            )}
          </PreviewContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewSubmit;
