import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import Wave from "../wave";
import theme from "../../styles/theme";
import { useTranslation } from "react-i18next";

const FeatureSection = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Wave
        amplitude={60}
        duration={5}
        fillStyle={`${theme.palette.primary.dark}`}
        frequency={3.0}
        waveHeightRatio={0.3}
      />

      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{
          alignItems: "center",
          py: 8,
          px: 4,
          backgroundColor: "primary.dark",
          color: "#fff",
          height: "60vh",
        }}
      >
        <Grid container maxWidth={"md"}>
          <Grid item xs={12} md={7}>
            <Box sx={{ textAlign: "left" }} data-aos="fade-up">
              <Typography variant="h3" component="h2" fontWeight={"600"} sx={{ mb: 2 }}>
              {t('Home.Feature.Title')}
              </Typography>
              <Typography variant="body1" fontWeight={"400"} fontSize={"1.5rem"} component="p">
              {t('Home.Feature.Desc')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box sx={{ mx: 4 }} data-aos="fade-up" data-aos-delay="200">
              <DeviceHubIcon sx={{ fontSize: isMobile ? "40vw" : "300px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Wave
        amplitude={60}
        duration={10}
        fillStyle={`${theme.palette.background.default}`}
        frequency={2}
        waveHeightRatio={0.3}
        backgroundColor={theme.palette.primary.dark}
      />
    </>
  );
};

export default FeatureSection;
