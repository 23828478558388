import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Badge,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Grid,
  Tooltip,
  Avatar,
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOrder } from "../../../hooks/useOrder";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";

function AdminOrderHistory() {
  const { t } = useTranslation();
  const { getAllOrders, updateOrderStatus, downloadOrderPDF } = useOrder(); // Use getAllOrders instead of getUserOrders
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // Filtered orders based on search/filter
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering orders
  const [anchorEl, setAnchorEl] = useState(null); // Anchor for filter menu
  const [statusAnchor, setStatusAnchorEl] = useState({});
  const [sortType, setSortType] = useState("mostRecent");

  const navigate = useNavigate();
  const handleRowClick = (orderId) => {
    navigate(`/dashboard/order/${orderId}`); // Navigate to order page
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const allOrders = await getAllOrders(); // Fetch all orders for admin
        const sortedOrders = allOrders.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setOrders(sortedOrders);
        setFilteredOrders(sortedOrders);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch orders."); // Handle error
      }
    };
    fetchOrders();
  }, [getAllOrders]);

  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="error"
            badgeContent={t("Dashboard.OrderHistory.Status.Pending")}
          />
        );
      case "in-progress":
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="info"
            badgeContent={t("Dashboard.OrderHistory.Status.InProgress")}
          />
        );
      case "completed":
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="success"
            badgeContent={t("Dashboard.OrderHistory.Status.Completed")}
          />
        );
      case "shipped":
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="secondary"
            badgeContent={t("Dashboard.OrderHistory.Status.Shipped")}
          />
        );
      default:
        return (
          <Badge
            style={{ padding: "0px 12px" }}
            color="error"
            badgeContent={t("Dashboard.OrderHistory.Status.Unknown")}
          />
        );
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  // Search/filter orders
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const searchValue = event.target.value.toLowerCase();

    const filtered = orders.filter(
      (order) =>
        order.id.toString().includes(searchValue) ||
        formatDate(order.created_at).toLowerCase().includes(searchValue) ||
        order.status.toLowerCase().includes(searchValue)
    );

    setFilteredOrders(filtered);
  };

  // Filter menu actions
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSort = (type) => {
    setSortType(type); // Store the current sort type
    let sorted;
    switch (type) {
      case "mostRecent":
        sorted = [...filteredOrders].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        break;
      case "oldest":
        sorted = [...filteredOrders].sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        break;
      case "highestAmount":
        sorted = [...filteredOrders].sort((a, b) => b.total_amount - a.total_amount);
        break;
      case "lowestAmount":
        sorted = [...filteredOrders].sort((a, b) => a.total_amount - b.total_amount);
        break;
      default:
        sorted = [...filteredOrders];
        break;
    }
    setFilteredOrders(sorted);
    handleCloseMenu();
  };

  const handleStatusChange = async (orderId, status) => {
    setStatusAnchorEl((prev) => ({ ...prev, [orderId]: null }));
    try {
      await updateOrderStatus(orderId, status);
      // Refetch all orders after updating the status
      const updatedOrders = await getAllOrders();
      setOrders(updatedOrders);

      // Reapply the current sort after fetching orders
      let sortedOrders;
      switch (sortType) {
        case "mostRecent":
          sortedOrders = updatedOrders.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          break;
        case "oldest":
          sortedOrders = updatedOrders.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          );
          break;
        case "highestAmount":
          sortedOrders = updatedOrders.sort((a, b) => b.total_amount - a.total_amount);
          break;
        case "lowestAmount":
          sortedOrders = updatedOrders.sort((a, b) => a.total_amount - b.total_amount);
          break;
        default:
          sortedOrders = updatedOrders;
          break;
      }
      setFilteredOrders(sortedOrders);
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleStatusMenuOpen = (e, orderId) => {
    e.stopPropagation();
    setStatusAnchorEl((prev) => ({ ...prev, [orderId]: e.currentTarget }));
  };

  const handleStatusMenuClose = (orderId) => {
    setStatusAnchorEl((prev) => ({ ...prev, [orderId]: null }));
  };

  const handleDownloadPDF = async (orderId) => {
    try {
      // console.log(orderId);
      await downloadOrderPDF(orderId);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        margin: "auto",
        borderRadius: 2,
      }}
    >
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        {/* Label */}
        <Grid item xs={12} md={5}>
          <Typography variant="h4" gutterBottom>
            {t("Dashboard.OrderHistory.Orders")}
          </Typography>
        </Grid>

        {/* Search and Filter */}
        <Grid item xs={12} md={7}>
          <Box display="flex" alignItems="center" width="100%">
            <TextField
              fullWidth
              placeholder={t("Dashboard.OrderHistory.Search")}
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ mr: 1 }}
            />
            <IconButton onClick={handleOpenMenu}>
              <FilterList />
            </IconButton>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
              <MenuItem onClick={() => handleSort("mostRecent")}>
                {t("Dashboard.OrderHistory.Sort.MostRecent")}
              </MenuItem>
              <MenuItem onClick={() => handleSort("oldest")}>
                {t("Dashboard.OrderHistory.Sort.Oldest")}
              </MenuItem>
              <MenuItem onClick={() => handleSort("highestAmount")}>
                {t("Dashboard.OrderHistory.Sort.HighestAmount")}
              </MenuItem>
              <MenuItem onClick={() => handleSort("lowestAmount")}>
                {t("Dashboard.OrderHistory.Sort.LowestAmount")}
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>

      {error && <Typography color="error">{error}</Typography>}
      {filteredOrders.length === 0 ? (
        <Typography>{t("Dashboard.OrderHistory.NoOrders")}</Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Dashboard.OrderHistory.Order")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Date")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Customer")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Total")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Items")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Status.Label")}</TableCell>
                <TableCell>{t("Dashboard.OrderHistory.Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order, index) => (
                <TableRow
                  key={order.id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "background.light" : "#E0E7F0",
                    "&:hover": {
                      backgroundColor:
                        index % 2 === 0 ? "rgba(181,195,248, 0.35)" : "rgba(180,196,218, 0.45)",
                      transition: "all 0.1s ease-in-out",
                    },
                  }}
                  onClick={(e) => {
                    if (!e.defaultPrevented) {
                      handleRowClick(order.id);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>
                    <Typography variant="body2" sx={{ fontWeight: "500", color: "primary.main" }}>
                      #{order.id}
                    </Typography>
                  </TableCell>
                  <TableCell>{formatDate(order.created_at)}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={order.User.avatar}
                        sx={{ mr: 1, width: 33, height: 33 }}
                        variant="rounded"
                      >
                        {order.User.avatar ? "" : order.User.firstname.charAt(0)}
                      </Avatar>
                      <Box>
                        <Typography variant="body2" fontWeight={500}>
                          {order.shipping_company_name || null}
                        </Typography>
                        <Typography
                          variant="body2"
                          color={order.shipping_company_name ? "text.light" : null}
                          fontWeight={order.shipping_company_name ? null : 500}
                        >
                          {order.shipping_firstname} {order.shipping_lastname}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{`${order.total_amount} kr`}</TableCell>
                  <TableCell>{`${order.OrderItems.length} ${t(
                    "Dashboard.OrderHistory.Items"
                  )}`}</TableCell>
                  <TableCell>{getStatusBadge(order.status)}</TableCell>
                  <TableCell>
                    {/* Status Dropdown Button */}
                    <Tooltip title={t("Dashboard.OrderHistory.ChangeStatus")} arrow>
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleStatusMenuOpen(e, order.id);
                        }}
                        sx={{
                          backgroundColor: "primary.light",
                          borderRadius: 2,
                          padding: 1,
                          "& .MuiTouchRipple-root span": {
                            borderRadius: 2,
                          },
                          "&:hover": {
                            backgroundColor: "primary.main",
                          },
                        }}
                      >
                        <EditIcon sx={{ fontSize: 15 }} />
                      </IconButton>
                    </Tooltip>

                    {/* Status Menu */}
                    <Menu
                      anchorEl={statusAnchor[order.id]}
                      open={Boolean(statusAnchor[order.id])}
                      onClose={(e) => {
                        e.stopPropagation(); // Prevent propagation of the close event
                        handleStatusMenuClose(order.id);
                      }}
                      PaperProps={{
                        sx: {
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                        },
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleStatusChange(order.id, "pending");
                        }}
                      >
                        <Badge
                          sx={{
                            backgroundColor: "error.main",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            mr: 2,
                          }}
                        />
                        {t("Dashboard.OrderHistory.Status.Pending")}
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleStatusChange(order.id, "in-progress");
                        }}
                      >
                        <Badge
                          sx={{
                            backgroundColor: "info.main",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            mr: 2,
                          }}
                        />
                        {t("Dashboard.OrderHistory.Status.InProgress")}
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleStatusChange(order.id, "completed");
                        }}
                      >
                        <Badge
                          sx={{
                            backgroundColor: "success.main",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            mr: 2,
                          }}
                        />
                        {t("Dashboard.OrderHistory.Status.Completed")}
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleStatusChange(order.id, "shipped");
                        }}
                      >
                        <Badge
                          sx={{
                            backgroundColor: "secondary.main",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            mr: 2,
                          }}
                        />
                        {t("Dashboard.OrderHistory.Status.Shipped")}
                      </MenuItem>
                    </Menu>

                    {/* Download PDF Button */}
                    <Tooltip title={t("Dashboard.OrderHistory.DownloadPDF")} arrow>
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleDownloadPDF(order.id);
                        }}
                        sx={{
                          backgroundColor: "success.light",
                          borderRadius: 2,
                          padding: 1,
                          "& .MuiTouchRipple-root span": {
                            borderRadius: 2,
                          },
                          "&:hover": {
                            backgroundColor: "success.main",
                          },
                          ml: 1,
                        }}
                      >
                        <DownloadIcon sx={{ fontSize: 15 }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

export default AdminOrderHistory;
