import { useCallback, useState, useEffect } from "react";
import usePriceChart from "../hooks/Price/usePriceChart";
import { useColorPricing } from "../hooks/Price/useColorPricing";

const usePriceCalculator = () => {
  const { priceCharts = [] } = usePriceChart();
  const { colorPricing = [] } = useColorPricing();
  const [priceInfo, setPriceInfo] = useState({});

  const formatPrice = (price) => {
    if (typeof price === "number") {
      const formattedPrice = price.toFixed(2);
      return parseFloat(formattedPrice).toString();
    }
    return "N/A";
  };

  const applyColorPricing = useCallback(
    (pricePerMotif, colors) => {
      const maxColorsRule = Math.max(...colorPricing.map((rule) => rule.colors));
      let colorRule = colorPricing.find((rule) => rule.colors === colors);

      // Apply default rule if no specific rule is found and colors exceed maxColorsRule
      if (!colorRule && colors > maxColorsRule) {
        colorRule = colorPricing.find((rule) => rule.is_default);
      }

      if (colorRule) {
        return pricePerMotif * (1 + colorRule.percentage_increase / 100);
      }

      return pricePerMotif;
    },
    [colorPricing]
  );

  const calculatePrice = useCallback(
    (quantity, width, height, colors = 1, itemId = null) => {
      if (!priceCharts.length) {
        if (itemId) {
          setPriceInfo((prev) => ({
            ...prev,
            [itemId]: { total: "N/A", pricePerMotif: "N/A" },
          }));
        }
        return { pricePerMotif: "N/A", total: "N/A" };
      }

      const areaCM2 = (width / 10) * (height / 10); // Convert from mm² to cm²
      const priceRange = priceCharts.find(({ range }) => {
        const [min, max] = range.split("-").map(Number);
        return quantity >= min && quantity <= max;
      });

      if (!priceRange) {
        if (itemId) {
          setPriceInfo((prev) => ({
            ...prev,
            [itemId]: { total: "N/A", pricePerMotif: "N/A" },
          }));
        }
        return { pricePerMotif: "N/A", total: "N/A" };
      }

      const sortedKeys = Object.keys(priceRange.prices).sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      );
      const sizeKey = sortedKeys.find((key) => parseFloat(key) >= areaCM2) || sortedKeys[0];
      let pricePerMotif = parseFloat(priceRange.prices[sizeKey]);
      const unfilteredPricePerMotif = pricePerMotif; // Store unfiltered price before color adjustments

      pricePerMotif = applyColorPricing(pricePerMotif, colors);

      const total = pricePerMotif * quantity;
      const result = {
        pricePerMotif: formatPrice(pricePerMotif),
        total: formatPrice(total),
        rawPricePerMotif: formatPrice(unfilteredPricePerMotif), // Pass unfiltered price
        rawTotal: formatPrice(unfilteredPricePerMotif * quantity), // Total before bonuses
      };

      if (itemId) {
        setPriceInfo((prev) => ({
          ...prev,
          [itemId]: result,
        }));
      } else {
        setPriceInfo(result);
      }

      return result;
    },
    [priceCharts, applyColorPricing]
  );

  useEffect(() => {
    // Ensure that priceCharts is not empty before iterating
    if (priceCharts && priceCharts.length > 0) {
      priceCharts.forEach((chart) => {
        if (chart.items && chart.items.length > 0) {
          chart.items.forEach((item) => {
            calculatePrice(item.quantity, item.width, item.height, item.colors, item.itemId);
          });
        }
      });
    }
  }, [priceCharts, calculatePrice]);

  return { calculatePrice, priceInfo };
};

export default usePriceCalculator;
