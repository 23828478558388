import React from "react";
import { Container } from "@mui/material";
import Hero from "../../components/home/hero";
import FeatureSection from "../../components/home/feature";
import ServicesSection from "../../components/home/service";

function Home() {
  return (
    <Container
      component="main"
      maxWidth="xxl"
      style={{ paddingLeft: "0px", paddingRight: "0px", textAlign: "center" }}
    >
      <Hero />
      <FeatureSection />
      <ServicesSection/>
    </Container>
  );
}

export default Home;
