import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  Avatar,
  Tabs,
  Tab,
} from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { useUser } from "../../../hooks/useUser";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import ReactFlagsSelect from "react-flags-select";

const BannerContainer = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
}));

const Banner = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "150px",
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(1, 1, 0, 0),
}));

const ProfileDetailsContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
}));

const AvatarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginTop: theme.spacing(-8),
  marginBottom: theme.spacing(2),
}));

const libraries = ["places"];

const ProfilePage = () => {
  const { userDetails } = useAuth();
  const { t } = useTranslation();
  const { user, loading, error, getUserByID, updateUserProfile } = useUser();
  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    company_name: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
    phone_number: "",
    email: "",
  });
  const [success, setSuccess] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const inputRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    if (userDetails && userDetails.user_id) {
      getUserByID(userDetails.user_id);
    }
  }, [userDetails, getUserByID]);
  
  useEffect(() => {
    if (user) {
      console.log(user); // Debug: log the user object
      setProfile({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        company_name: user.company_name || "",
        address: user.address || "",
        city: user.city || "",
        country: user.country || "",
        postal_code: user.postal_code || "",
        phone_number: user.phone_number || "",
        email: user.email || "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);

    try {
      await updateUserProfile(userDetails.user_id, profile);
      setSuccess(true);
    } catch (error) {
      console.error("Failed to update profile", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Function to handle place selection and autofill the form fields
  const handleOnPlacesChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0]; // Get the first place
      const addressComponents = place.address_components;

      const getComponent = (components, type, useShortName = false) => {
        const component = components.find((comp) => comp.types.includes(type));
        return component ? (useShortName ? component.short_name : component.long_name) : "";
      };

      // Autofill the address fields
      setProfile((prev) => ({
        ...prev,
        address:
          getComponent(addressComponents, "route") +
          " " +
          getComponent(addressComponents, "street_number"),
        city:
          getComponent(addressComponents, "locality") ||
          getComponent(addressComponents, "postal_town"),
        postal_code: getComponent(addressComponents, "postal_code"),
        country: getComponent(addressComponents, "country", true), // Use short_name for country
      }));
    }
  };

  const handleCountrySelect = (countryCode) => {
    setProfile((prev) => ({
      ...prev,
      country: countryCode,
    }));
  };

  if (loading) return <CircularProgress />;
  if (loadError) return <Alert severity="error">{t("Dashboard.MyProfile.LoadError")}</Alert>;

  return (
    <Container component="main" sx={{ px: { xs: 0 } }}>
      <BannerContainer elevation={3}>
        <Banner />
        <AvatarContainer>
          <Avatar
            src={userDetails.avatar}
            alt={userDetails?.firstname || "User Avatar"}
            sx={{ width: 128, height: 128, mb: 2 }}
          >
            {userDetails.avatar ? "" : userDetails.firstname.charAt(0)}
          </Avatar>
          <Typography variant="h5">{userDetails?.firstname}</Typography>
          <Typography color={"text.secondary"} variant="body">
            {userDetails?.email}
          </Typography>
        </AvatarContainer>
      </BannerContainer>

      <ProfileDetailsContainer elevation={3}>
        <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ mb: 4 }}>
          <Tab label={t("Dashboard.MyProfile.PersonalInfo")} />
          <Tab label={t("Dashboard.MyProfile.AccountSettings")} />
        </Tabs>

        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{t("Dashboard.MyProfile.Success")}</Alert>}

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {tabValue === 0 && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.FirstName")}
                    name="firstname"
                    value={profile.firstname}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.LastName")}
                    name="lastname"
                    value={profile.lastname}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.Company")}
                    name="company_name"
                    value={profile.company_name}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  {isLoaded && (
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef.current = ref)}
                      onPlacesChanged={handleOnPlacesChanged}
                    >
                      <TextField
                        label={t("Dashboard.MyProfile.Address")}
                        name="address"
                        value={profile.address}
                        onChange={handleChange}
                        fullWidth
                        rows={2}
                      />
                    </StandaloneSearchBox>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.City")}
                    name="city"
                    value={profile.city}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReactFlagsSelect
                    selected={profile.country}
                    onSelect={handleCountrySelect}
                    searchable
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.PostalCode")}
                    name="postal_code"
                    value={profile.postal_code}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("Dashboard.MyProfile.PhoneNumber")}
                    name="phone_number"
                    value={profile.phone_number}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t("Dashboard.MyProfile.Email")}
                    name="email"
                    value={profile.email}
                    onChange={handleChange}
                    fullWidth
                    disabled
                  />
                </Grid>
              </>
            )}

            {tabValue === 1 && (
              <Grid item xs={12}>
                <Typography variant="h6">{t("Dashboard.MyProfile.AccountSettings")}</Typography>
                {/* Add account settings form fields here */}
              </Grid>
            )}
          </Grid>
          {tabValue === 0 && (
            <Box sx={{ mt: 3, textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                {t("Dashboard.MyProfile.Submit")}
              </Button>
            </Box>
          )}
        </Box>
      </ProfileDetailsContainer>
    </Container>
  );
};

export default ProfilePage;
