import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

const secureFetch = async (url, options = {}, refreshCallback) => {
  let accessToken = localStorage.getItem("accessToken");

  // Check if the access token is expired
  if (accessToken && jwtDecode(accessToken).exp < Date.now() / 1000) {
    if (typeof refreshCallback === "function") {
      await refreshCallback();
    } else {
      throw new Error("refreshCallback is not a function");
    }
    accessToken = localStorage.getItem("accessToken");
  }

  // Set the authorization header
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`,
  };

  // Use Axios to make the request
  try {
    const response = await axios({
      url,
      ...options,
      withCredentials: true,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      if (typeof refreshCallback === "function") {
        await refreshCallback();
        accessToken = localStorage.getItem("accessToken");
        options.headers.Authorization = `Bearer ${accessToken}`;
        const retryResponse = await axios({
          url,
          ...options,
          withCredentials: true,
        });
        return retryResponse;
      } else {
        throw new Error("refreshCallback is not a function");
      }
    }
    throw error;
  }
};

export default secureFetch;