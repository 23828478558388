import {
  Paper,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Grid,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import usePriceCalculator from "../../../components/priceCalculator";
import { useTranslation } from "react-i18next";

function Calculator() {
  const { calculatePrice, priceInfo, loading, error } = usePriceCalculator();
  const [quantity, setQuantity] = useState(15);
  const [colors, setColors] = useState(1);
  const [sizeDetails, setSizeDetails] = useState({ width: 0, height: 0, area: 0 });
  const [inputError, setInputError] = useState(false); // State to track input validation
  const { t } = useTranslation();

  useEffect(() => {
    if (sizeDetails.width > 0 && sizeDetails.height > 0) {
      calculatePrice(quantity, sizeDetails.width, sizeDetails.height, colors);
      setInputError(false); // No error if valid inputs
    } else {
      setInputError(true); // Show error if inputs are invalid
    }
  }, [quantity, sizeDetails.width, sizeDetails.height, colors, calculatePrice]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newValue = parseFloat(value) || 0;

    setSizeDetails((prevDetails) => {
      const newDetails = { ...prevDetails, [name]: newValue };
      if (name === "width" || name === "height") {
        newDetails.area = (newDetails.width * newDetails.height) / 100; // assuming the area needs to be in cm²
      }
      return newDetails;
    });
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleColorChange = (event) => {
    setColors(event.target.value);
  };

  const quantityTable = [
    15, 20, 40, 60, 80, 100, 125, 150, 200, 250, 300, 400, 500, 600, 700, 800, 900, 1000, 1500,
    2000,
  ];

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, backgroundColor: "#f9f9f9" }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        {t("Dashboard.PriceCalculator.Label")} [W.I.P]
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <TextField
            select
            label={t("Dashboard.PriceCalculator.Quantity")}
            value={quantity}
            size="small"
            variant="outlined"
            onChange={handleQuantityChange}
            sx={{ width: "100%" }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              },
            }}
          >
            {quantityTable.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label={t("Dashboard.PriceCalculator.Width")}
            name="width"
            type="number"
            size="small"
            variant="outlined"
            onChange={handleChange}
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            error={inputError && sizeDetails.width === 0} // Display error if width is 0
            helperText={
              inputError && sizeDetails.width === 0
                ? t("Dashboard.PriceCalculator.WidthRequired")
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label={t("Dashboard.PriceCalculator.Height")}
            name="height"
            type="number"
            size="small"
            variant="outlined"
            onChange={handleChange}
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            error={inputError && sizeDetails.height === 0} // Display error if height is 0
            helperText={
              inputError && sizeDetails.height === 0
                ? t("Dashboard.PriceCalculator.HeightRequired")
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label={t("Dashboard.PriceCalculator.Area")}
            name="area"
            type="number"
            size="small"
            value={sizeDetails.area}
            variant="outlined"
            disabled
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm²</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            select
            label={t("Dashboard.PriceCalculator.Colors")}
            value={colors}
            size="small"
            variant="outlined"
            onChange={handleColorChange}
            sx={{ width: "100%" }}
          >
            {[1, 2, 3, 4, 5].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "background",
              boxShadow: "0 0 8px rgba(0, 0, 0, 0.45)",
              height: "100%",
            }}
          >
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              {t("Dashboard.PriceCalculator.PricePer")}
            </Typography>
            <Typography variant="body1" fontSize={23} fontWeight={500} gutterBottom>
              {sizeDetails.width && sizeDetails.height ? priceInfo.pricePerMotif || "-" : "-"} kr
            </Typography>
            {colors > 1 && priceInfo.pricePerMotif > priceInfo.rawPricePerMotif && (
              <Typography variant="body2" color="text.secondary">
                {t("Dashboard.PriceCalculator.ColorImpact")}: +
                {parseFloat(priceInfo.pricePerMotif - priceInfo.rawPricePerMotif).toFixed(2)} kr
              </Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "background",
              boxShadow: "0 0 8px rgba(0, 0, 0, 0.45)",
            }}
          >
            <Typography variant="subtitle2" sx={{ mb: 0.5, color: "text.secondary" }}>
              {t("Dashboard.PriceCalculator.Total")}
            </Typography>
            <Typography variant="body1" fontSize={23} fontWeight={500} gutterBottom>
              {sizeDetails.width && sizeDetails.height ? priceInfo.total || "-" : "-"} kr
            </Typography>
            {colors > 1 && priceInfo.total > priceInfo.rawTotal && (
              <Typography variant="body2" color="text.secondary">
                {t("Dashboard.PriceCalculator.ColorImpact")}: +
                {parseFloat(priceInfo.total - priceInfo.rawTotal).toFixed(2)} kr
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {inputError && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {t("Dashboard.PriceCalculator.InputValidation")}
        </Alert>
      )}
    </Paper>
  );
}

export default Calculator;
