import { Box, Button, Paper, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NotFound() {
  const {t} = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="50vh"
      sx={{ bgcolor: "background.default", padding: 3, textAlign: "center" }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 80, color: "error.main", mb: 2 }} />
      <Typography variant="h5" color="text.secondary" sx={{ mb: 3 }}>
      {t("NotFound.Oops")} {t("NotFound.PageNotFound")}
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
      {t("NotFound.GoHome")}
      </Button>
    </Box>
  );
}

function NotFoundDash() {
  const {t} = useTranslation();

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        textAlign: "center",
        margin: "auto",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <ErrorOutlineIcon sx={{ fontSize: 60, color: "error.main", mb: 2 }} />
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
        {t("NotFound.PageNotFound")}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        {t("NotFound.Desc")}
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/dashboard">
        {t("NotFound.GoDash")}
        </Button>
      </Box>
    </Paper>
  );
}

export { NotFound, NotFoundDash };
