import { Paper, Typography } from "@mui/material";

function SettingsPage() {
  return (
    <Paper elevation={0} sx={{ padding: 2 }}>
      <Typography variant="h4">Settings</Typography>
      <p>This is the settings page.</p>
    </Paper>
  );
}
export default SettingsPage;
