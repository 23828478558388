import { useState, useCallback, useEffect } from "react";
import secureFetch from "../secureFetch";
import { API_URL } from "../../apiConfig";
import { useAuth } from "../useAuth";

const baseUrl = `${API_URL}/pricerule`;

const usePricingRules = () => {
  const { refreshToken } = useAuth();
  const [pricingRules, setPricingRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all pricing rules
  const fetchPricingRules = useCallback(async () => {
    setLoading(true);
    try {
      const response = await secureFetch(`${baseUrl}`, {}, refreshToken);
      setPricingRules(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [refreshToken]);

  useEffect(() => {
    fetchPricingRules();
  }, [fetchPricingRules]);

  // Update a pricing rule
  const updatePricingRule = useCallback(
    async (ruleId, value) => {
      // console.log(ruleId, value); // This should help in debugging
      try {
        const response = await secureFetch(
          `${baseUrl}/${ruleId}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({ value }),
          },
          refreshToken
        );
        setPricingRules((prevRules) =>
          prevRules.map((rule) => (rule.rule_id === ruleId ? { ...rule, value: value } : rule))
        );
      } catch (err) {
        setError(err.message);
        throw err; // Re-throw to let caller handle the error as well
      }
    },
    [refreshToken]
  );

  // Delete a pricing rule
  const deletePricingRule = useCallback(
    async (ruleId) => {
      try {
        const response = await secureFetch(
          `${baseUrl}/${ruleId}`,
          {
            method: "DELETE",
          },
          refreshToken
        );
        setPricingRules((prev) => prev.filter((rule) => rule.id !== ruleId));
      } catch (err) {
        setError(err.message);
        throw err; // Re-throw to let caller handle the error as well
      }
    },
    [refreshToken]
  );

  return {
    pricingRules,
    fetchPricingRules,
    updatePricingRule,
    deletePricingRule,
    loading,
    error,
  };
};

export default usePricingRules;
