import { useState, useEffect, useCallback } from "react";
import secureFetch from "./secureFetch";
import { useAuth } from "./useAuth";
import { API_URL } from "../apiConfig";

const baseUrl = `${API_URL}/motif`;

export function useMotifs() {
  const { refreshToken } = useAuth();
  const [motifs, setMotifs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get Motifs
  const fetchMotifs = useCallback(async () => {
    const controller = new AbortController();
    setLoading(true);
    setError(null);
    try {
      const response = await secureFetch(
        `${baseUrl}`,
        {
          signal: controller.signal,
        },
        refreshToken
      );
      setMotifs(response.data);
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error(err);
        setError(err.message || "Something went wrong with fetching motifs");
      }
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [refreshToken]);

  useEffect(() => {
    fetchMotifs();
  }, [fetchMotifs]);

  // Get Motif by ID
  const getMotifById = useCallback(
    async (id) => {
      setLoading(true);
      setError(null);
      try {
        const response = await secureFetch(`${baseUrl}/${id}`, {}, refreshToken);
        return response.data; // Axios responses have the data property
      } catch (err) {
        console.error(err);
        setError(err.message || "Failed to fetch motif");
      } finally {
        setLoading(false);
      }
    },
    [refreshToken]
  );

  // Create Motif
  const createMotif = async (motifData) => {
    try {
      const formData = new FormData();
      // console.log("Submitting Motif Data:", motifData);

      // Append each key in the motifData to the formData
      Object.keys(motifData).forEach((key) => {
        if (motifData[key] !== null && motifData[key] !== undefined) {
          formData.append(key, motifData[key]);
        }
      });

      // for (let [key, value] of formData.entries()) {
      //   console.log(key, value);
      // }

      const response = await secureFetch(
        `${baseUrl}`,
        {
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        refreshToken
      );

      const data = response.data;
      setMotifs((prev) => [...prev, data]);
      return data;
    } catch (err) {
      console.error("Create motif error:", err);
      setError(err.message || "Failed to create motif");
      throw err;
    }
  };

  // Update Motif
  const updateMotif = async (id, motifData, imageFile) => {
    const formData = new FormData();
    // console.log("Submitting Motif Data:", motifData);
    try {
      // Append each key in the motifData to the formData
      Object.keys(motifData).forEach((key) => {
        if (motifData[key] !== null && motifData[key] !== undefined) {
          // Append all keys including image_url if it exists
          formData.append(key, motifData[key]);
        }
      });

      // Only append imageFile if it is provided
      if (imageFile) {
        formData.append("image_url", imageFile);
      }

      const response = await secureFetch(
        `${baseUrl}/${id}`,
        {
          method: "PUT",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        refreshToken
      );

      const updatedMotif = response.data;
      setMotifs((prev) => prev.map((item) => (item.id === id ? updatedMotif : item)));
      return updatedMotif;
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to update motif");
      throw err;
    }
  };

  // Delete Motif
  const deleteMotif = async (id) => {
    try {
      await secureFetch(
        `${baseUrl}/${id}`,
        {
          method: "DELETE",
        },
        refreshToken
      );
      setMotifs((prev) => prev.filter((item) => item.id !== id));
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to delete motif");
      throw err;
    }
  };

  return {
    motifs,
    loading,
    error,
    getMotifById,
    createMotif,
    updateMotif,
    deleteMotif,
    refreshMotifs: fetchMotifs,
  };
}
