import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { CartContext } from "../../context/CartContext";
import usePricingRules from "../../hooks/Price/usePriceRule";
import usePriceCalculator from "../../components/priceCalculator";
import {
  Container,
  Paper,
  Box,
  Typography,
  List,
  Button,
  Grid,
  Card,
  Tooltip,
  CardMedia,
  IconButton,
  Divider,
  CircularProgress,
  keyframes,
  TextField,
  Collapse,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import OrderFormDialog from "../../components/checkout/orderForm";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { useUser } from "../../hooks/useUser";
import ReactFlagsSelect from "react-flags-select";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import { useOrder } from "../../hooks/useOrder";

const libraries = ["places"];

const CartPage = () => {
  const { cart, handleRemoveFromCart, handleClearCart } = useContext(CartContext);
  const { calculatePrice, priceInfo } = usePriceCalculator();
  const { pricingRules } = usePricingRules();
  const { userDetails } = useAuth();
  const { user, getUserByID } = useUser();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [animationStage, setAnimationStage] = useState("initial"); // initial, shrinking, loading, success
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [rememberDetails, setRememberDetails] = useState(false);
  const { updateUserProfile } = useUser();
  const [formFields, setFormFields] = useState({
    firstname: "",
    lastname: "",
    company_name: "",
    address: "",
    city: "",
    postal_code: "",
    country: "",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { createOrder } = useOrder();

  const inputRef = useRef(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API_KEY,
    libraries: libraries, // Use the static variable
  });

  if (loadError) {
    console.error("Error loading Google Maps API:", loadError);
  }

  // Fetch user details if userDetails exist and update form fields
  useEffect(() => {
    if (userDetails && userDetails.user_id) {
      getUserByID(userDetails.user_id);
    }
  }, [userDetails, getUserByID]);

  // Update form fields after user details are fetched
  useEffect(() => {
    if (user) {
      setFormFields({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        company_name: user.company_name || "",
        address: user.address || "",
        city: user.city || "",
        postal_code: user.postal_code || "",
        country: user.country || "",
      });
    }
  }, [user]);

  useEffect(() => {
    if (cart && cart.length > 0) {
      cart.forEach((item) => {
        if (item.width && item.height) {
          calculatePrice(item.quantity, item.width, item.height, item.colors, item.id);
        }
      });
    }
  }, [cart, calculatePrice]);

  // Calculate lump sum from pricing rules
  const lumpSumPrice = useMemo(() => {
    const rule = pricingRules.find((rule) => rule.rule_type === "lump_sum");
    return rule ? parseFloat(rule.value) : 0;
  }, [pricingRules]);

  // Calculate subtotal by aggregating total prices from priceInfo
  const subtotal = useMemo(() => {
    return cart.reduce((acc, item) => {
      const itemTotal = priceInfo[item.id] ? parseFloat(priceInfo[item.id].total) : 0;
      const itemTotalWithLumpSum = itemTotal + lumpSumPrice;
      return acc + itemTotalWithLumpSum;
    }, 0);
  }, [cart, priceInfo, lumpSumPrice]);

  // Calculate grand total by adding lump sum to subtotal
  const grandTotal = useMemo(() => {
    return subtotal;
  }, [subtotal]);

  const validateFields = () => {
    let newErrors = {};
    const requiredFields = ["firstname", "lastname", "address", "city", "postal_code", "country"];

    requiredFields.forEach((field) => {
      if (!formFields[field]) {
        newErrors[field] = `${field.replace("_", " ")} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleRemoveFromCart = (itemId) => {
  //   dispatch({ type: "Remove", id: itemId });
  //   // removeItemFromCart(itemId);
  // };

  const handlePlaceOrder = async () => {
    if (validateFields()) {
      setLoading(true);
      setAnimationStage("shrinking");

      setTimeout(async () => {
        try {
          if (rememberDetails) {
            await updateUserProfile(userDetails.user_id, {
              firstname: formFields.firstname,
              lastname: formFields.lastname,
              company_name: formFields.company_name,
              address: formFields.address,
              city: formFields.city,
              postal_code: formFields.postal_code,
              country: formFields.country,
            });
          }
          setAnimationStage("loading");

          // Prepare items for order creation
          const orderItems = cart.map((item) => ({
            motif_id: item.id, // Assuming itemId is the motif ID
            quantity: item.quantity,
          }));

          const newOrder = await createOrder(orderItems);
          setAnimationStage("success");
          handleClearCart();
          setOrderPlaced(true);
          navigate(`/dashboard/order/${newOrder.id}`);
        } catch (error) {
          console.error("Failed to create order", error);
          // You can also set a "failure" animation state here if you have one
        } finally {
          setLoading(false);
        }
      }, 500); // Delay for shrinking animation
    } else {
      // If validation fails, show form errors
      setShowForm(true);
    }
  };

  // Function to handle place selection and autofill the form fields
  const handleOnPlacesChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0]; // Get the first place
      const addressComponents = place.address_components;

      // Helper function to extract data from the address_components array
      const getComponent = (components, type, useShortName = false) => {
        const component = components.find((comp) => comp.types.includes(type));
        return component ? (useShortName ? component.short_name : component.long_name) : "";
      };

      // Update form fields based on the selected place
      const updatedFields = {
        address:
          getComponent(addressComponents, "route") +
          " " +
          getComponent(addressComponents, "street_number"),
        city:
          getComponent(addressComponents, "locality") ||
          getComponent(addressComponents, "postal_town"),
        postal_code: getComponent(addressComponents, "postal_code"),
        country: getComponent(addressComponents, "country", true), // Use short_name for country
      };

      setFormFields((prev) => ({
        ...prev,
        ...updatedFields,
      }));

      // Clear errors for the fields that were auto-filled
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "",
        city: "",
        postal_code: "",
        country: "",
      }));
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = () => {
    setDialogOpen(false);
    // Simulate a 2-second delay for placing the order
    // Here, you would proceed to place the order with the filled user details
    // navigate('/order/confirmation') or similar route
  };

  // const handleUpdateCart = (itemId, quantity) => {
  //   updateCartItem({ itemId, quantity });
  // };

  const handleFieldChange = (e, name, value) => {
    if (name && value) {
      // When name and value are provided manually, e.g., for ReactFlagsSelect
      setFormFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      // For regular TextField inputs
      const { name, value } = e.target;
      setFormFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Clear the error for the specific field if it exists
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name || e.target.name]: "", // Clear error for the field
    }));
  };

  if (!user || Object.keys(user).length === 0) {
    return (
      <Container component="main" maxWidth="lg" sx={{ textAlign: "center", mt: 10 }}>
        <Paper elevation={3} sx={{ padding: 5, borderRadius: 2 }}>
          <Typography variant="h4" fontWeight={500} gutterBottom>
            {t("Checkout.NonUserTitle")}
          </Typography>
          <Typography variant="body1" mb={3}>
            {t("Checkout.NonUserSubtitle")}
          </Typography>
          <Button variant="contained" color="primary" component={Link} to="/login">
            {t("LogIn")}
          </Button>
        </Paper>
      </Container>
    );
  }

  const bounceAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
            <Typography variant="h4" mb={3} fontWeight={500}>
              {t("Checkout.Label")}
            </Typography>
            {cart.length === 0 ? (
              <Typography variant="body1">{t("Checkout.EmptyCart")}</Typography>
            ) : (
              <List>
                {cart.map((item) => {
                  return (
                    <Card key={item.id} sx={{ boxShadow: "none", marginBottom: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={5} sm={4} md={3}>
                          <CardMedia
                            component="img"
                            image={item.imageUrl}
                            alt={item.name}
                            height="100%"
                            sx={{
                              objectFit: "contain",
                              borderRadius: 1,
                              backgroundColor: "grey",
                              padding: 1,
                            }}
                          />
                        </Grid>
                        <Grid item xs={7} sm={8} md={9}>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            height={"100%"}
                          >
                            <Box mb={2}>
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Typography variant="h5" fontWeight={600} noWrap>
                                  {item.name}
                                </Typography>
                                <Tooltip title="Remove from Cart" arrow>
                                  <IconButton
                                    size="small"
                                    aria-label="remove"
                                    onClick={() => handleRemoveFromCart(item.id)}
                                    sx={{
                                      borderRadius: 2,
                                      padding: 1,
                                      "& .MuiTouchRipple-root span": {
                                        borderRadius: 2,
                                      },
                                      "&:hover": {
                                        backgroundColor: "lightgray",
                                      },
                                    }}
                                  >
                                    <ClearRoundedIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  variant="subtitle2"
                                  paddingX={2}
                                  color="text.main"
                                  sx={{
                                    fontWeight: "500",
                                    backgroundColor: "secondary.main",
                                    borderRadius: 1,
                                  }}
                                >
                                  {item.article_number}
                                </Typography>
                              </Box>

                              <Typography variant="body2" color="text.secondary" marginTop={1}>
                                {item.quantity}x
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {item.width}mm x {item.height}mm ={" "}
                                {parseFloat(
                                  (((item.width / 10) * item.height) / 10).toFixed(2)
                                ).toString()}
                                cm²
                              </Typography>
                            </Box>
                            {/* <Box display="flex" alignItems="center" marginTop={1}>
                              <TextField
                                select
                                label="Quantity"
                                value={item.quantity}
                                size="small"
                                variant="outlined"
                                onChange={(e) => handleUpdateCart(item.itemId, e.target.value)}
                                sx={{ width: "150px", marginRight: 2 }}
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200,
                                      },
                                    },
                                  },
                                }}
                              >
                                {quantityTable.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box> */}
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  );
                })}
              </List>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
            <Typography variant="h5" fontWeight={500} gutterBottom>
              {t("Checkout.OrderDetails")}
            </Typography>
            <List>
              {cart.map((item, index) => {
                const itemPriceInfo = priceInfo[item.id] || {
                  pricePerMotif: t("Checkout.Calculating"),
                  total: t("Checkout.Calculating"),
                };
                const itemTotalWithLumpSum = parseFloat(itemPriceInfo.total) + lumpSumPrice;
                return (
                  <Box key={item.id} mb={2}>
                    <Typography variant="body1" fontWeight={500}>
                      {index + 1}. {item.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.quantity} x {itemPriceInfo.pricePerMotif}kr + {t("Checkout.LumpSum")} (
                      {lumpSumPrice}kr) ={" "}
                      <span style={{ fontWeight: 500 }}>{itemTotalWithLumpSum} kr</span>
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                  </Box>
                );
              })}
            </List>
            {/* <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="body2">{t("Checkout.SubTotal")}</Typography>
              <Typography variant="body2">
                {parseFloat(subtotal.toFixed(2)).toString()} kr
              </Typography>
            </Box> */}
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="body1" fontWeight={500}>
                {t("Checkout.GrandTotal")}
              </Typography>
              <Typography variant="body1" fontWeight={500}>
                {parseFloat(grandTotal.toFixed(2)).toString()} kr
              </Typography>
            </Box>
            <Button
              variant="contained"
              fullWidth
              onClick={handlePlaceOrder}
              disabled={loading || orderPlaced} // Disable the button when loading or orderPlaced is true
              sx={{
                minHeight: "50px",
                position: "relative",
                backgroundColor: orderPlaced ? "success.main" : "primary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: orderPlaced ? "success.dark" : "primary.dark",
                },
                "&.Mui-disabled": {
                  backgroundColor: orderPlaced ? "success.main" : "primary.main", // Keep background color when disabled
                  color: "white", // Keep text color when disabled
                  opacity: 1, // Ensure opacity doesn't change when disabled
                },
              }}
            >
              {animationStage === "loading" ? (
                <CircularProgress
                  size={35} // Adjust the size as needed
                  sx={{
                    color: "white", // Adjust the color if needed
                    transition: "transform 0.5s ease",
                    transform: animationStage === "shrinking" ? "scale(0.1)" : "scale(1)",
                  }}
                />
              ) : animationStage === "success" ? (
                <CheckIcon
                  sx={{
                    fontSize: 37,
                    animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                  }}
                />
              ) : (
                <Typography
                  fontWeight={500}
                  sx={{
                    transition: "transform 0.5s ease, opacity 0.5s ease",
                    transform: animationStage === "shrinking" ? "scale(0.1)" : "scale(1)",
                    opacity: animationStage === "shrinking" ? 0 : 1,
                  }}
                >
                  {t("Checkout.PlaceOrder")}
                </Typography>
              )}
            </Button>
            <Collapse in={showForm}>
              <Box mt={3}>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body1" fontWeight={500} mb={2}>
                  {t("Checkout.MissingDetails")}
                </Typography>

                <TextField
                  label={t("Dashboard.MyProfile.FirstName")}
                  name="firstname"
                  fullWidth
                  value={formFields.firstname}
                  onChange={handleFieldChange}
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                  required
                  sx={{ mb: 2 }}
                />

                <TextField
                  label={t("Dashboard.MyProfile.LastName")}
                  name="lastname"
                  fullWidth
                  value={formFields.lastname}
                  onChange={handleFieldChange}
                  error={!!errors.lastname}
                  helperText={errors.lastname}
                  required
                  sx={{ mb: 2 }}
                />

                <TextField
                  label={t("Dashboard.MyProfile.Company")}
                  name="company_name"
                  fullWidth
                  value={formFields.company_name}
                  onChange={handleFieldChange}
                  helperText={errors.company_name}
                  sx={{ mb: 2 }}
                />

                {isLoaded && (
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputRef.current = ref)}
                    onPlacesChanged={handleOnPlacesChanged}
                  >
                    <TextField
                      label={t("Dashboard.MyProfile.Address")}
                      name="address"
                      fullWidth
                      value={formFields.address}
                      onChange={handleFieldChange}
                      error={!!errors.address}
                      helperText={errors.address}
                      required
                      sx={{ mb: 2 }}
                    />
                  </StandaloneSearchBox>
                )}

                <TextField
                  label={t("Dashboard.MyProfile.City")}
                  name="city"
                  fullWidth
                  value={formFields.city}
                  onChange={handleFieldChange}
                  error={!!errors.city}
                  helperText={errors.city}
                  required
                  sx={{ mb: 2 }}
                />
                <TextField
                  label={t("Dashboard.MyProfile.PostalCode")}
                  name="postal_code"
                  fullWidth
                  value={formFields.postal_code}
                  onChange={handleFieldChange}
                  error={!!errors.postal_code}
                  helperText={errors.postal_code}
                  required
                  sx={{ mb: 2 }}
                />
                <ReactFlagsSelect
                  selected={formFields.country} // Use the country from formFields
                  searchable
                  selectedSize={20}
                  onSelect={(code) => handleFieldChange(null, "country", code)}
                />

                {errors.country && (
                  <Typography variant="body2" color="error" gutterBottom>
                    {errors.country}
                  </Typography>
                )}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberDetails}
                    onChange={(e) => setRememberDetails(e.target.checked)}
                  />
                }
                label={t("Checkout.RememberDetails")}
              />
            </Collapse>
          </Paper>
        </Grid>
      </Grid>
      {/* <OrderFormDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
      /> */}
    </Container>
  );
};

export default CartPage;
