import { useState, useEffect, useCallback } from "react";
import { jwtDecode } from "jwt-decode"; // Ensure you're importing correctly
import { API_URL } from "../apiConfig";

const safeJwtDecode = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error("Failed to decode JWT:", error);
    return null;
  }
};

const baseUrl = `${API_URL}/auth`;

export function useAuth() {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userDetails, setUserDetails] = useState(() => {
    const token = localStorage.getItem("accessToken");
    return token ? safeJwtDecode(token) : {};
  });

const refreshToken = useCallback(async () => {
    try {
      const refresh = localStorage.getItem("refreshToken");
      if (!refresh) throw new Error("No refresh token available");

      const response = await fetch(`${baseUrl}/refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: refresh }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken); // Save new refresh token if provided
      setUserDetails(jwtDecode(data.accessToken)); // Update all user details from the new token
      return true;
    } catch (error) {
      console.error("Error refreshing token:", error);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      setUserDetails({});
      return false;
    }
  }, []);

  useEffect(() => {
    const checkTokenExpiry = async () => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // current time in seconds
        if (decoded.exp < currentTime) {
          await refreshToken();
        } else {
          setUserDetails(decoded); // Ensure userDetails are always up to date with the token
        }
      }
    };

    checkTokenExpiry();
  }, [refreshToken]);

  const loginUser = async (userData) => {
    try {
      setIsError(false);
      const response = await fetch(`${baseUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error. Status: ${response.status}`);
      }
      const data = await response.json();
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      setUserDetails(safeJwtDecode(data.accessToken));
      return true;
    } catch (error) {
      console.log(error);
      setIsError(true);
      setErrorMessage(error.message);
      return false;
    }
  };

  const registerUser = async (userData) => {
    try {
      const formData = new FormData();
      console.log("Submitting User Data:", userData);

      // Append each key in the userData to the formData
      Object.keys(userData).forEach((key) => {
        if (userData[key] !== null && userData[key] !== undefined) {
          formData.append(key, userData[key]);
        }
      });

      // for (let [key, value] of formData.entries()) {
      //   console.log(key, value);
      // }

      const response = await fetch(`${baseUrl}/register`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        throw new Error(`Failed to register user: ${errorResponse}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error registering user:", error);
      setErrorMessage(error.message || "Failed to register user");
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    setUserDetails({});
  };

  return { loginUser, registerUser, refreshToken, logout, isError, errorMessage, userDetails };
}
