export const initialPricingData = [
  {
    range: "15-19",
    prices: {
      "25cm2": 20.2,
      "50cm2": 22.2,
      "100cm2": 24.3,
      "200cm2": 26.3,
      "300cm2": 28.3,
      "400cm2": 30.3,
      "500cm2": 34.4,
      "600cm2": 38.35,
      "700cm2": 42.45,
      "800cm2": 54.6,
      "900cm2": 66.75,
      "1000cm2": 80.1,
    },
  },
  {
    range: "20-39",
    prices: {
      "25cm2": 12.6,
      "50cm2": 13.9,
      "100cm2": 15.2,
      "200cm2": 16.4,
      "300cm2": 17.7,
      "400cm2": 18.95,
      "500cm2": 21.5,
      "600cm2": 24.0,
      "700cm2": 26.5,
      "800cm2": 34.1,
      "900cm2": 41.7,
      "1000cm2": 50.05,
    },
  },
  {
    range: "40-59",
    prices: {
      "25cm2": 9.25,
      "50cm2": 10.2,
      "100cm2": 11.25,
      "200cm2": 12.2,
      "300cm2": 13.15,
      "400cm2": 14.05,
      "500cm2": 15.9,
      "600cm2": 17.9,
      "700cm2": 19.75,
      "800cm2": 25.4,
      "900cm2": 31.05,
      "1000cm2": 37.3,
    },
  },
  {
    range: "60-79",
    prices: {
      "25cm2": 8.0,
      "50cm2": 8.9,
      "100cm2": 10.3,
      "200cm2": 11.25,
      "300cm2": 12.55,
      "400cm2": 13.45,
      "500cm2": 15.55,
      "600cm2": 17.0,
      "700cm2": 18.9,
      "800cm2": 24.05,
      "900cm2": 29.2,
      "1000cm2": 35.05,
    },
  },
  {
    range: "80-99",
    prices: {
      "25cm2": 7.0,
      "50cm2": 7.8,
      "100cm2": 9.05,
      "200cm2": 9.85,
      "300cm2": 11.0,
      "400cm2": 11.75,
      "500cm2": 13.6,
      "600cm2": 15.45,
      "700cm2": 17.35,
      "800cm2": 21.9,
      "900cm2": 26.45,
      "1000cm2": 31.75,
    },
  },
  {
    range: "100-124",
    prices: {
      "25cm2": 6.2,
      "50cm2": 7.0,
      "100cm2": 7.9,
      "200cm2": 8.65,
      "300cm2": 9.45,
      "400cm2": 10.15,
      "500cm2": 11.6,
      "600cm2": 13.2,
      "700cm2": 14.9,
      "800cm2": 18.85,
      "900cm2": 22.8,
      "1000cm2": 27.4,
    },
  },
  {
    range: "125-149",
    prices: {
      "25cm2": 5.85,
      "50cm2": 6.55,
      "100cm2": 7.6,
      "200cm2": 8.3,
      "300cm2": 9.05,
      "400cm2": 10.0,
      "500cm2": 11.3,
      "600cm2": 12.95,
      "700cm2": 14.5,
      "800cm2": 18.3,
      "900cm2": 22.1,
      "1000cm2": 26.55,
    },
  },
  {
    range: "150-199",
    prices: {
      "25cm2": 5.45,
      "50cm2": 6.1,
      "100cm2": 7.1,
      "200cm2": 7.8,
      "300cm2": 8.85,
      "400cm2": 9.45,
      "500cm2": 11.0,
      "600cm2": 12.6,
      "700cm2": 14.15,
      "800cm2": 17.7,
      "900cm2": 21.25,
      "1000cm2": 25.5,
    },
  },
  {
    range: "200-249",
    prices: {
      "25cm2": 4.7,
      "50cm2": 5.2,
      "100cm2": 6.05,
      "200cm2": 6.8,
      "300cm2": 7.5,
      "400cm2": 8.05,
      "500cm2": 9.25,
      "600cm2": 10.75,
      "700cm2": 12.25,
      "800cm2": 15.3,
      "900cm2": 18.35,
      "1000cm2": 22.0,
    },
  },
  {
    range: "250-299",
    prices: {
      "25cm2": 4.4,
      "50cm2": 5.0,
      "100cm2": 5.85,
      "200cm2": 6.55,
      "300cm2": 7.15,
      "400cm2": 7.65,
      "500cm2": 8.85,
      "600cm2": 10.35,
      "700cm2": 11.75,
      "800cm2": 14.75,
      "900cm2": 17.75,
      "1000cm2": 21.25,
    },
  },
  {
    range: "300-399",
    prices: {
      "25cm2": 4.05,
      "50cm2": 4.45,
      "100cm2": 5.3,
      "200cm2": 6.05,
      "300cm2": 6.85,
      "400cm2": 7.25,
      "500cm2": 8.45,
      "600cm2": 9.9,
      "700cm2": 11.35,
      "800cm2": 13.95,
      "900cm2": 16.6,
      "1000cm2": 19.85,
    },
  },
  {
    range: "400-499",
    prices: {
      "25cm2": 3.65,
      "50cm2": 4.2,
      "100cm2": 4.9,
      "200cm2": 5.55,
      "300cm2": 6.45,
      "400cm2": 6.9,
      "500cm2": 8.2,
      "600cm2": 9.6,
      "700cm2": 10.75,
      "800cm2": 13.2,
      "900cm2": 15.65,
      "1000cm2": 17.95,
    },
  },
  {
    range: "500-599",
    prices: {
      "25cm2": 3.3,
      "50cm2": 3.85,
      "100cm2": 4.5,
      "200cm2": 5.1,
      "300cm2": 6.0,
      "400cm2": 6.4,
      "500cm2": 7.65,
      "600cm2": 9.0,
      "700cm2": 10.1,
      "800cm2": 12.35,
      "900cm2": 14.65,
      "1000cm2": 17.1,
    },
  },
  {
    range: "600-699",
    prices: {
      "25cm2": 3.1,
      "50cm2": 3.6,
      "100cm2": 4.2,
      "200cm2": 4.8,
      "300cm2": 5.65,
      "400cm2": 6.05,
      "500cm2": 7.25,
      "600cm2": 8.55,
      "700cm2": 9.75,
      "800cm2": 11.7,
      "900cm2": 13.65,
      "1000cm2": 15.85,
    },
  },
  {
    range: "700-799",
    prices: {
      "25cm2": 2.9,
      "50cm2": 3.4,
      "100cm2": 3.95,
      "200cm2": 4.55,
      "300cm2": 5.4,
      "400cm2": 5.75,
      "500cm2": 6.95,
      "600cm2": 8.2,
      "700cm2": 9.25,
      "800cm2": 11.1,
      "900cm2": 12.95,
      "1000cm2": 15.2,
    },
  },
  {
    range: "800-899",
    prices: {
      "25cm2": 2.75,
      "50cm2": 3.2,
      "100cm2": 3.75,
      "200cm2": 4.35,
      "300cm2": 5.15,
      "400cm2": 5.5,
      "500cm2": 6.65,
      "600cm2": 7.9,
      "700cm2": 8.85,
      "800cm2": 10.75,
      "900cm2": 12.65,
      "1000cm2": 15.05,
    },
  },
  {
    range: "900-999",
    prices: {
      "25cm2": 2.6,
      "50cm2": 3.1,
      "100cm2": 3.6,
      "200cm2": 4.15,
      "300cm2": 4.95,
      "400cm2": 5.4,
      "500cm2": 6.4,
      "600cm2": 7.65,
      "700cm2": 8.55,
      "800cm2": 10.35,
      "900cm2": 12.15,
      "1000cm2": 14.1,
    },
  },
  {
    range: "1000-1499",
    prices: {
      "25cm2": 2.5,
      "50cm2": 2.95,
      "100cm2": 3.45,
      "200cm2": 4.0,
      "300cm2": 4.85,
      "400cm2": 5.15,
      "500cm2": 6.25,
      "600cm2": 7.4,
      "700cm2": 8.3,
      "800cm2": 10.05,
      "900cm2": 11.4,
      "1000cm2": 14.0,
    },
  },
  {
    range: "1500-1999",
    prices: {
      "25cm2": 1.9,
      "50cm2": 2.3,
      "100cm2": 2.65,
      "200cm2": 3.15,
      "300cm2": 3.85,
      "400cm2": 4.1,
      "500cm2": 5.05,
      "600cm2": 6.1,
      "700cm2": 6.85,
      "800cm2": 8.4,
      "900cm2": 10.15,
      "1000cm2": 12.35,
    },
  },
  {
    range: "2000-2999",
    prices: {
      "25cm2": 1.65,
      "50cm2": 1.95,
      "100cm2": 2.25,
      "200cm2": 2.95,
      "300cm2": 3.25,
      "400cm2": 3.5,
      "500cm2": 4.65,
      "600cm2": 5.95,
      "700cm2": 6.25,
      "800cm2": 7.85,
      "900cm2": 9.05,
      "1000cm2": 10.9,
    },
  },
];
